<template>
  <div>
    <!-- 设置快问快答弹窗 -->
    <div class="setting-exam-dialog" v-if="dialogVisible">
      <div class="setting-exam-wrapper">
        <p class="title">
          设置快问快答
          <i class="vh-icon-close" @click="cancelExam"></i>
        </p>
        <div class="setting-exam-level">
          <h4 class="level_title">选择快问快答</h4>
          <div class="setting-exam-ctx">
            <span class="select-exam" @click.prevent.stop="openSelectDialog">
              <i class="vh-icon-circle-plus-outline"></i>
              选择快问快答
            </span>
            <div class="setting-exam-ctx__tags" v-if="checkedExamList.length">
              <div
                :class="['tags__item', item.error_status == 1 ? 'tags__error' : '']"
                v-for="item in checkedExamList"
                :key="item.id"
              >
                <span class="tags_title">{{ item.title }}</span>
                <span>({{ item.total_score }}分)</span>
                <i class="vh-icon-close" @click.stop="removeItem(item)"></i>
              </div>
            </div>
            <div class="setting-exam-ctx__error" v-if="errorText">{{ errorText }}</div>
          </div>
        </div>
        <div class="setting-exam-level">
          <h4>设置条件</h4>
          <vh-radio-group
            v-model="setScoreForm.type"
            class="setting-exam-radios"
            @input="changeValue"
          >
            <vh-radio :label="1">
              <div class="setting-exam-radios__ctx">
                <p :class="['score--text', setScoreForm.type == 1 ? 'active' : '']">
                  参与以上快问快答，无得分要求
                </p>
              </div>
            </vh-radio>
            <vh-radio :label="2">
              <div class="setting-exam-radios__ctx">
                <p :class="['score--text', setScoreForm.type == 2 ? 'active' : '']">
                  参与以上快问快答且每个得分要求为
                </p>
                <div v-if="setScoreForm.type == 2" class="score--setting">
                  <label>得分&ge;</label>
                  <vh-input
                    placeholder="请输入"
                    type="number"
                    autocomplete="off"
                    v-model.trim="setScoreForm.score"
                    size="small"
                  ></vh-input>
                </div>
              </div>
            </vh-radio>
            <vh-radio :label="3">
              <div class="setting-exam-radios__ctx">
                <p :class="['score--text', setScoreForm.type == 3 ? 'active' : '']">
                  参与以上快问快答且总分要求为
                </p>
                <div v-if="setScoreForm.type == 3" class="score--setting">
                  <label>总分&ge;</label>
                  <vh-input
                    placeholder="请输入"
                    type="number"
                    v-model.trim="setScoreForm.totalScore"
                    size="small"
                  ></vh-input>
                  <span v-if="checkedExamList.length">
                    当前已选快问快答满分为：{{ checkTotal }}分
                  </span>
                </div>
                <div class="setting-exam-ctx__error" v-if="errorTotalText">
                  {{ errorTotalText }}
                </div>
              </div>
            </vh-radio>
          </vh-radio-group>
        </div>
        <!-- 按钮区间 -->
        <div slot="footer" class="dialog-footer">
          <vh-button size="medium" v-preventReClick type="info" plain round @click="cancelExam">
            取消
          </vh-button>
          <vh-button size="medium" v-preventReClick type="primary" round @click="sureExam">
            确定
          </vh-button>
        </div>
      </div>
    </div>

    <!-- 选择快问快答弹窗 -->
    <checked-exam
      ref="checkedExamDialog"
      :isLotterySelect="true"
      :checkedExamList="checkedExamList"
      @checkExam="checkExam"
      @prev="preview"
    ></checked-exam>
    <!-- 预览 -->
    <preview-exam ref="examPrev" maxWidth="580px" maxHeight="420px"></preview-exam>
  </div>
</template>
<script>
  import examServer from '@/utils/examServer';
  import CheckedExam from '../../../PlatformModule/ExamList/components/selectExam.vue';
  import previewExam from '../../../PlatformModule/ExamList/exam-prev.vue';
  import regRule from '@/utils/reg-rule.js';
  export default {
    data() {
      return {
        dialogVisible: false,
        lotteryId: '',
        setScoreForm: {
          type: 1,
          score: '',
          totalScore: ''
        },
        errorText: '',
        errorTotalText: '',
        checkedExamList: []
      };
    },
    components: {
      CheckedExam,
      previewExam
    },
    computed: {
      checkTotal() {
        if (!this.checkedExamList.length) return;
        return this.checkedExamList.reduce((acc, cur) => {
          return acc + Number(cur.total_score);
        }, 0);
      }
    },
    methods: {
      // 打开 - 弹窗
      open(id, info) {
        this.dialogVisible = true;
        this.lotteryId = id || '';
        this.errorText = '';
        this.errorTotalText = '';
        const qaInfo = JSON.parse(JSON.stringify(info));
        this.resetInfo(qaInfo);
      },
      changeValue() {
        this.handleResetInfo();
        this.errorText = '';
        this.errorTotalText = '';
      },
      resetInfo(info) {
        this.setScoreForm.type = info.type;
        info.type == 2
          ? (this.setScoreForm.score = info.score)
          : (this.setScoreForm.totalScore = info.score);
        this.checkedExamList = info.fqa_list;
      },
      // 预览
      preview(examObj) {
        const prevCom = this.$refs.examPrev;
        prevCom.open(examObj.id, examObj.title);
      },
      // 关闭 - 弹窗
      handleClose() {
        this.dialogVisible = false;
        this.setScoreForm.score = '';
        this.setScoreForm.totalScore = '';
        this.handleResetInfo();
      },
      handleResetInfo() {
        this.checkedExamList = this.checkedExamList.map(item => {
          item.error_status = 0;
          return item;
        });
      },
      // 移除 - 单个选中
      removeItem(item) {
        this.checkedExamList.splice(this.checkedExamList.indexOf(item), 1);
      },
      checkExam(list) {
        this.checkedExamList.push(...list);
        // let id = 'id';
        // this.checkedExamList = this.checkedExamList.reduce(
        //   (all, next) => (all.some(atom => atom[id] == next[id]) ? all : [...all, next]),
        //   []
        // );
      },
      messageInfo(text, type = 'error') {
        this.$vhMessage({
          message: text,
          showClose: true,
          // duration: 0,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      // 确定 - 设置快问快答
      sureExam() {
        const checkList = this.checkedExamList.map(item => item.id);
        if (!checkList.length) {
          this.messageInfo('请选择快问快答');
          return;
        }

        if (this.setScoreForm.type != 1) {
          const pattern = regRule.num9; // 正整数的正则表达式
          let value =
            this.setScoreForm.type == 2 ? this.setScoreForm.score : this.setScoreForm.totalScore;
          if (!value || !pattern.test(value)) {
            this.messageInfo('请输入正整数');
            return;
          }
        }
        const params = {
          ids: checkList.join(','),
          type: this.setScoreForm.type,
          score:
            this.setScoreForm.type == 2 ? this.setScoreForm.score : this.setScoreForm.totalScore
        };
        examServer
          .checkFqaScore(params)
          .then(res => {
            if (res.code === 200) {
              if (res.data.pass_status) {
                const examInfo = {
                  type: this.setScoreForm.type,
                  score:
                    this.setScoreForm.type == 2
                      ? this.setScoreForm.score
                      : this.setScoreForm.totalScore,
                  fqa_list: this.checkedExamList
                };
                this.errorText = '';
                this.handleClose();
                this.$emit('selectExam', examInfo);
              } else {
                this.errorHandle(res.data);
              }
            } else {
              this.$message.error(res.msg);
              this.dialogVisible = true;
            }
          })
          .catch(res => {
            this.dialogVisible = true;
            this.$message.error('添加失败');
          });
      },
      errorHandle(data) {
        const repeatList = data?.deleted_ids || [];
        const singleScoreList = data?.single_score_dissatisfy_ids || [];
        const totalScore = data?.total_score_dissatisfy || false;
        if (repeatList.length) {
          this.errorTotalText = '';
          this.errorText = '该快问快答已删除';
          this.checkedExamList = this.checkedExamList.map(item => {
            if (repeatList.includes(Number(item.id))) {
              item.error_status = 1;
            } else {
              item.error_status = 0;
            }
            return item;
          });
          return;
        }
        if (singleScoreList.length) {
          this.errorTotalText = '';
          this.errorText = '该快问快答未满足得分要求';
          this.checkedExamList = this.checkedExamList.map(item => {
            if (singleScoreList.includes(Number(item.id))) {
              item.error_status = 1;
            } else {
              item.error_status = 0;
            }
            return item;
          });
          return;
        }
        if (totalScore) {
          this.errorText = '';
          this.errorTotalText = '快问快答未满足总得分要求';
        }
        this.dialogVisible = true;
      },
      // 取消 - 设置快问快答
      cancelExam() {
        this.handleClose();
      },
      // 打开资料库
      openSelectDialog() {
        this.$refs.checkedExamDialog.open();
      }
    }
  };
</script>
<style lang="less" scoped>
  .setting-exam-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.3);
    .setting-exam-wrapper {
      position: absolute;
      top: 48%;
      left: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
      width: 640px;
      padding-bottom: 24px;
      border-radius: 4px;
      .title {
        padding: 32px;
        font-size: 20px;
        i {
          float: right;
          font-size: 15px;
          color: #1a1a1a;
          cursor: pointer;
        }
      }
    }
    .dialog-footer {
      display: flex;
      padding: 0 32px;
      justify-content: flex-end;
    }
    /deep/.vh-input__inner {
      line-height: 1px !important;
    }
  }
  .setting-exam-level {
    margin-bottom: 24px;
    display: flex;
    padding: 0 32px;
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      // margin-bottom: 16px;
      display: inline-block;
      width: 90px;
      text-align: right;
      color: #1a1a1a;
      font-weight: normal;
      font-size: 14px;
    }
    .level_title {
      line-height: 36px;
    }
    .setting-exam-ctx {
      padding: 0 12px;
      width: calc(100% - 90px);
      .select-exam {
        display: inline-block;
        background: #fff;
        width: 148px;
        height: 36px;
        border: 1px solid #d9d9d9;
        border-radius: 50px;
        font-size: 14px;
        color: #262626;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        i {
          // vertical-align: middle;
          color: rgba(0, 0, 0, 0.85);
        }
        &:hover {
          background: #fb3a32;
          color: #fff;
          border: 1px solid transparent;
          i {
            color: #fff;
          }
        }
      }
      .setting-exam-ctx__tags {
        margin-top: 16px;
        max-height: 132px;
        overflow-y: auto;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        .tags__item {
          padding: 3px 12px;
          background: #f0f0f0;
          border-radius: 50px;
          cursor: pointer;
          span {
            color: #595959;
            font-size: 14px;
          }
          .tags_title {
            display: inline-block;
            max-width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: middle;
            padding-right: 5px;
            line-height: 20px;
          }
          .vh-icon-close {
            font-size: 12px;
            color: #595959;
            cursor: pointer;
            padding-left: 5px;
          }
          &:hover {
            background: #fff2f0;
            span {
              color: #fb3a32;
            }
            .vh-icon-close {
              color: #fb3a32;
            }
          }
          &.tags__error {
            background: #fff2f0;
            border: 1px solid #fb3a32;
            span {
              color: #fb3a32;
            }
            .vh-icon-close {
              color: #fb3a32;
            }
          }
        }
      }
      &__error {
        color: #fb3a32;
        font-size: 14px;
        padding-top: 8px;
      }
    }
    .setting-exam-radios {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: baseline;
      padding: 0 12px;
      width: calc(100% - 90px);
      .vh-radio {
        display: flex;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .setting-exam-radios__ctx {
        color: rgba(0, 0, 0, 0.65);
        .score--setting {
          margin-top: 12px;
          label {
            margin-right: 8px;
          }
          .vh-input,
          input {
            width: 160px;
          }
          span {
            margin-left: 8px;
          }
        }
        .score--text {
          // color: rgba(0, 0, 0, 0.65);
          &.active {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }
</style>
