<template>
  <!-- 活动下和账号下共用的奖品列表 -->
  <div class="question-wrap">
    <pageTitle pageTitle="奖品" v-if="!isInWebinar">
      <div slot="content">
        1.创建奖品不限制数量
        <br />
        2.奖品名称：不支持特殊符号、表情
        <br />
        3.奖品图片：尺寸240*240px，大小不超过2M
      </div>
    </pageTitle>
    <div class="head-operat" v-show="total || isSearch" :class="source == 0 ? 'head-prize' : ''">
      <vh-button size="medium" type="primary" round @click="createPrize">创建奖品</vh-button>
      <vh-button
        size="medium"
        round
        plain
        type="default"
        class="transparent-btn"
        v-if="isInWebinar"
        @click="prizeMeterial"
      >
        资料库
      </vh-button>
      <vh-button
        size="medium"
        round
        plain
        class="head-btn batch-del transparent-btn"
        @click="allDelete(null)"
        :disabled="!prizeChecked.length"
      >
        批量删除
      </vh-button>
      <p v-if="isInWebinar" class="switch-box">
        <span class="switch-box__desc">抽奖页允许观众查看奖品池</span>
        <vh-switch v-model="isCanLookPrizePool"></vh-switch>
      </p>
      <div class="search-right">
        <vh-input
          round
          class="search-tag"
          placeholder="请输入奖品名称"
          v-model="keyword"
          clearable
          @clear="searchTableList"
          @keyup.enter.native="searchTableList"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchTableList"></i>
        </vh-input>
        <vh-button
          v-if="total && isInWebinar"
          type="primary"
          size="medium"
          round
          @click="sureAwardSetting"
          v-preventReClick
        >
          保存
        </vh-button>
      </div>
    </div>
    <div class="no-live" v-if="!total && !isSearch">
      <noData :nullType="'nullData'" :text="'您还未添加奖品，快去添加吧~'">
        <vh-button type="primary" round @click="createPrize" v-preventReClick>创建奖品</vh-button>
        <vh-button v-if="isInWebinar" round plain @click="prizeMeterial" v-preventReClick>
          资料库
        </vh-button>
      </noData>
    </div>
    <div class="prize-table" v-show="total || isSearch">
      <table-list
        ref="tableList"
        :pageLimit="isInWebinar ? 20 : 10"
        :manageTableData="tableData"
        :tabelColumnLabel="tabelColumn"
        :tableRowBtnFun="tableRowBtnFun"
        :isIconBtn="isInWebinar ? true : false"
        :totalNum="total"
        :width="200"
        scene="prize"
        @onHandleBtnClick="onHandleBtnClick"
        @getTableList="getTableList"
        @changeTableCheckbox="changeTableCheckbox"
      ></table-list>
      <noData :nullType="'search'" v-if="!total"></noData>
    </div>
    <!-- <div class="prize-table-save" v-if="total && isInWebinar">
      <vh-button
        size="medium"
        type="info"
        plain
        round
        class="transparent-btn"
        v-preventReClick
        @click="backToList"
      >
        返回列表
      </vh-button>
      <vh-button size="medium" @click="backPreLottery" type="info" plain round>上一步</vh-button>
      <vh-button type="primary" round @click="sureAwardSetting" v-preventReClick>保存</vh-button>
    </div> -->
    <create-prize
      ref="createPrize"
      @getTableList="getTableList"
      :prizeInfo="prizeInfo"
      :liveTotal="total"
      @touchmove.prevent
    ></create-prize>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import createPrize from '../LiveModule/MaterialSet/components/createPrize';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal } from '@/utils/utils';
  import Sortable from 'sortablejs';
  export default {
    name: 'prize',
    props: {
      source: {
        type: String,
        required: false,
        default: '1'
      },
      roomId: {
        type: String,
        required: false,
        default: ''
      },
      lotteryId: {
        type: [String, Number],
        required: false,
        default: ''
      }
    },
    data() {
      return {
        isCanLookPrizePool: false,
        total: 0,
        isSearch: false,
        keyword: '',
        userId: JSON.parse(sessionOrLocal.get('userId')),
        prizeInfo: {},
        isDelete: false,
        searchAreaLayout: [
          {
            key: 'keyword'
          }
        ],
        tabelColumn: [
          // {
          //   label: '奖品id',
          //   key: 'prize_id',
          // },
          {
            label: '奖品图片',
            key: 'img'
          },
          {
            label: '奖品名称',
            key: 'prize_name'
          },
          {
            label: '奖品描述',
            key: 'prize_desc'
          },
          {
            label: '奖品类型',
            key: 'award_type'
          },
          {
            label: '创建时间',
            key: 'create_time'
          }
        ],
        tableRowBtnFun: [
          { name: '复制', methodName: 'copy' },
          { name: '编辑', methodName: 'edit' },
          { name: '删除', methodName: 'del' }
        ],
        prizeChecked: [],
        tableData: []
      };
    },
    computed: {
      isInWebinar() {
        return this.$route.params.str;
      }
    },
    components: {
      PageTitle,
      createPrize,
      noData
    },
    created() {
      if (this.source == 0) {
        this.tabelColumn = [
          {
            label: '奖品图片',
            key: 'img'
          },
          {
            label: '奖品名称',
            key: 'prize_name'
          },
          {
            label: '奖品数量',
            key: 'quantity'
          },
          {
            label: '奖品描述',
            key: 'award_desc'
          },
          {
            label: '奖品类型',
            key: 'award_type'
          },
          {
            label: '创建时间',
            key: 'create_time'
          }
        ];
        this.tableRowBtnFun = [
          { name: '复制', methodName: 'copy', iconClass: ['iconfont-v3', 'saasline-copy'] },
          { name: '编辑', methodName: 'edit', iconClass: ['iconfont-v3', 'saasline-el-icon-edit'] },
          { name: '删除', methodName: 'del', iconClass: ['iconfont-v3', 'saasline-delete'] },
          { name: '移动', methodName: '', iconClass: ['iconfont-v3', 'saasline-rank'] }
        ];
      }
    },
    mounted() {
      if (!this.isInWebinar) {
        this.getTableList();
      }
    },
    methods: {
      backToList() {
        this.$emit('backToList');
      },
      sureAwardSetting() {
        const params = {
          can_look_prize_pool: this.isCanLookPrizePool ? 1 : 0,
          lottery_id: this.lotteryId,
          award_ids: this.total && this.tableData.map(item => item.award_id).join(','),
          webinar_id: this.$route.params.str
        };
        this.$fetch('saveAwardSetting', params)
          .then(res => {
            console.log('---===---', res);
            this.$vhMessage.success('保存成功');
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `保存失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      backPreLottery() {
        this.$emit('backPreLottery', 'second');
      },
      setRowSort() {
        this.$nextTick(() => {
          const dom = document.querySelector(
            '.prize-table .vh-table .vh-table__body-wrapper tbody'
          );
          Sortable.create(dom, {
            ghostClass: '',
            handle: '.saasline-rank',
            onEnd: ({ newIndex, oldIndex }) => {
              this.tableData.splice(newIndex, 0, this.tableData.splice(oldIndex, 1)[0]);
              let newArray = this.tableData.slice(0);
              this.tableData = [];
              this.$nextTick(() => {
                this.tableData = newArray;
              });
            }
          });
        });
      },
      onHandleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      searchTableList() {
        if (this.keyword) {
          this.$vhall_paas_port({
            k: this.$route.params.str ? 100332 : 100539,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str || '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getTableList('search');
      },
      getTableList(params) {
        let pageInfo = this.$refs.tableList.pageInfo;
        //获取分页信息
        let formParams = {}; //获取搜索参数
        if (params == 'search') {
          pageInfo.pageNum = 1;
          pageInfo.pos = 0;
        }
        if (this.source == '0') {
          formParams.award_name = this.keyword;
          formParams.lottery_id = this.lotteryId;
        } else {
          formParams.keyword = this.keyword;
        }
        this.isSearch = this.keyword ? true : false;
        formParams.source = this.source;
        let obj = Object.assign({}, pageInfo, formParams);
        this.$fetch(this.isInWebinar ? 'getAwardList' : 'getPrizeList', obj).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.count || res.data.total;
          this.isCanLookPrizePool = Boolean(res.data.can_look_award_pool);
          this.tableData.map(item => {
            if (this.isInWebinar) {
              item.img = item.img_path || item.award_img_url;
              item.prize_id = item.award_id;
              item.prize_name = item.award_name;
            } else {
              item.img = item.img_path;
            }
          });
          this.$nextTick(() => {
            this.setRowSort();
          });
        });
      },
      // 复制
      copy(that, { rows }) {
        if (that.source == 0 && Number(that.total) >= 20) {
          that.$vhMessage.error('每个活动最多显示20个奖品，超过20个后无法关联，需要将原有奖品删除');
          return;
        }

        let params = {};

        if (that.source == 0) {
          params = {
            award_id: rows.prize_id,
            webinar_id: that.$route.params.str
          };
        } else {
          params = {
            prize_id: rows.prize_id,
            source: that.source,
            room_id: that.roomId
          };
        }
        console.log(
          '-----------',
          that.isInWebinar,
          that.isInWebinar ? 'copyLotteryAward' : 'copyPrize'
        );
        that
          .$fetch(that.isInWebinar ? 'copyLotteryAward' : 'copyPrize', that.$params(params))
          .then(res => {
            that.$vhall_paas_port({
              k: that.$route.params.str ? 100327 : 100536,
              data: {
                business_uid: that.userId,
                user_id: '',
                webinar_id: that.$route.params.str || '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            that.$vhMessage.success('复制成功');
            that.getTableList();
          })
          .catch(res => {
            that.$vhMessage({
              message: res.msg || `复制失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 编辑
      edit(that, { rows }) {
        console.log(rows);
        that.prizeInfo = rows;
        that.$nextTick(() => {
          that.$refs.createPrize.dialogVisible = true;
        });
      },
      // 删除
      del(that, { rows }) {
        that.deleteConfirm(rows.prize_id, 2);
      },
      deleteConfirm(id, index) {
        this.$vhConfirm('删除后，此奖品将无法使用，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            let params = {};
            if (this.isInWebinar) {
              params = {
                award_ids: id,
                room_id: this.roomId,
                lottery_id: this.lotteryId
              };
            } else {
              params = {
                prize_id: id,
                source: this.source,
                room_id: this.roomId
              };
            }
            this.$fetch(this.isInWebinar ? 'deleteAward' : 'delPrize', this.$params(params))
              .then(res => {
                if (res.code == 200) {
                  this.getTableList('search');
                  let k = 0;
                  if (this.$route.params.str) {
                    k = index === 1 ? 100329 : 100328;
                  } else {
                    k = index === 1 ? 100538 : 100537;
                  }
                  this.$vhall_paas_port({
                    k: k,
                    data: {
                      business_uid: this.userId,
                      user_id: '',
                      webinar_id: this.$route.params.str || '',
                      refer: '',
                      s: '',
                      report_extra: {},
                      ref_url: '',
                      req_url: ''
                    }
                  });
                  this.$vhMessage({
                    message: `删除成功`,
                    showClose: true,
                    // duration: 0,
                    type: 'success',
                    customClass: 'zdy-info-box'
                  });
                }
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `删除失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          })
          .catch(() => {
            this.$vhMessage({
              message: `已取消删除`,
              showClose: true,
              // duration: 0,
              type: 'info',
              customClass: 'zdy-info-box'
            });
          });
      },
      allDelete(id) {
        id = this.prizeChecked.join(',');
        this.deleteConfirm(id, 1);
      },
      // 选中
      changeTableCheckbox(val) {
        this.prizeChecked = val.map(item => item.prize_id);
      },
      // 创建奖品
      createPrize() {
        if (this.source == 0 && Number(this.total) >= 20) {
          this.$vhMessage({
            message: `每个活动最多显示20个奖品，超过20个后无法关联，需要将原有奖品删除`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        if (this.tableData.length) {
          this.$refs.tableList.clearSelect();
        }
        this.prizeInfo = {};
        this.$refs.createPrize.dialogVisible = true;
      },
      // 从资料库中选择
      prizeMeterial() {
        this.$refs.createPrize.dialogPrizeVisible = true;
        // document.documentElement.style.overflow = "hidden";
        // document.documentElement.style.position = "fixed";
        // document.body.scroll = "no";
      }
    }
  };
</script>

<style lang="less" scoped>
  .prize-table {
    width: 100%;
    .layout--right--main();
    .padding-table-list();
  }
  .question-wrap {
    height: 100%;
    width: 100%;
    ::v-deep .vh-card__body {
      width: 100%;
      padding: 32px 24px;
    }
    .head-operat {
      margin-bottom: 20px;
      .head-btn {
        display: inline-block;
      }
      .switch-box {
        display: inline-block;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        margin-left: 12px;
        &__desc {
          margin-right: 8px;
        }
      }

      ::v-deep .set-upload {
        position: relative;
        span {
          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .search-right {
        float: right;
        .search-tag {
          width: 220px;
          margin-right: 16px;
          &.vh-input {
            ::v-deep .vh-input__inner {
              height: 36px;
              line-height: 36px;
            }
          }
        }
      }
    }
    .head-prize {
      margin: 0 24px;
    }
    .prize-table-save {
      margin-top: 16px;
      display: flex;
      justify-content: center;
    }
    .prize-no {
      // height: calc(100vh - 260px);
      text-align: center;
      margin-top: 208px;
    }
    .no-live {
      padding-bottom: 150px;
    }
  }
</style>
