<template>
  <div class="question-dialog" v-if="settingQuestionShow">
    <!-- 设置问卷弹窗 -->
    <div class="question-select">
      <p class="question-select_title">
        设置问卷
        <i class="vh-icon-close" @click="closeQuestion"></i>
      </p>
      <div class="question-dialog_wrapper">
        <div class="question-left">设置条件</div>
        <div class="question-right">
          <div class="question-right_way">
            <vh-radio v-model="condition" @input="changeValue" :label="1">提交任意问卷</vh-radio>
            <br />
            <!-- <vh-radio v-model="condition" :label="2">提交全部问卷</vh-radio> -->
            <vh-radio v-model="condition" :label="3">提交指定问卷</vh-radio>
          </div>
          <div class="question-right_appoint" v-if="condition == 3">
            <span @click="selectQuestion">
              <i class="vh-icon-circle-plus-outline"></i>
              选择问卷
            </span>
          </div>
          <div class="question-right_list" v-if="condition == 3">
            <div
              :class="['list_item', item.error_status == 1 ? 'tags__error' : '']"
              v-for="(item, index) in questionList"
              :key="index"
            >
              <span>{{ item.title }}</span>
              <i class="vh-icon-close" @click="deleteSelectQuestion(item.question_id)"></i>
            </div>
          </div>
          <div class="question-right_error">{{ errorText }}</div>
          <div slot="footer" class="dialog-footer">
            <vh-button
              size="medium"
              v-preventReClick
              type="info"
              plain
              @click.prevent.stop="closeQuestion"
              round
            >
              取消
            </vh-button>
            <vh-button size="medium" v-preventReClick type="primary" @click="sureQuestion" round>
              确定
            </vh-button>
          </div>
        </div>
      </div>
    </div>
    <checked-question
      :isLotterySelect="true"
      :questionList="questionList"
      ref="checkedQuestionDialog"
      @addQuestion="addQuestionInfo"
    ></checked-question>
  </div>
</template>
<script>
  import checkedQuestion from './questionBase.vue';
  export default {
    data() {
      return {
        settingQuestionShow: false,
        condition: 3,
        total: 0,
        errorText: '',
        lotteryId: '',
        questionList: [],
        checkedQuestionList: []
      };
    },
    components: {
      checkedQuestion
    },
    methods: {
      // 打开选择问卷弹窗
      selectQuestion() {
        this.$refs.checkedQuestionDialog.dataBaseVisible = true;
      },
      openSettingDialog(id, info) {
        this.lotteryId = id || '';
        const questionInfo = JSON.parse(JSON.stringify(info));
        this.condition = questionInfo.type;
        this.questionList = questionInfo.survey_list;
        this.settingQuestionShow = true;
        this.errorText = '';
        this.resetQuestionInfo();
      },
      changeValue() {
        this.errorText = '';
        this.resetQuestionInfo();
      },
      resetQuestionInfo() {
        this.questionList = this.questionList.map(item => {
          item.error_status = 0;
          return item;
        });
      },
      addQuestionInfo(list) {
        this.questionList.push(...list);
        // let id = 'question_id';
        // this.questionList = this.questionList.reduce(
        //   (all, next) => (all.some(atom => atom[id] == next[id]) ? all : [...all, next]),
        //   []
        // );
      },
      deleteSelectQuestion(id) {
        this.questionList.map((item, index) => {
          if (item.question_id === id) {
            this.questionList.splice(index, 1);
          }
        });
      },
      sureQuestion() {
        const questionInfo = {
          type: this.condition,
          survey_list: []
        };
        if (this.condition != 3) {
          questionInfo.survey_list = [];
          this.$emit('selectQuestion', questionInfo);
          this.settingQuestionShow = false;
          return;
        }
        const checkList = this.questionList.map(item => item.question_id);
        if (!checkList.length) {
          this.$vhMessage({
            message: '请选择指定问卷',
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        this.$fetch('checkSelectSurvey', {
          survey_ids: checkList.join(',')
        })
          .then(res => {
            if (res.code === 200) {
              const noList = res.data?.non_existent || [];
              if (noList.length) {
                this.settingQuestionShow = true;
                this.errorText = '该问卷已被删除';
                this.questionList = this.questionList.map(item => {
                  if (noList.includes(Number(item.question_id))) {
                    item.error_status = 1;
                  }
                  return item;
                });
              } else {
                questionInfo.survey_list = this.questionList;
                this.errorText = '';
                this.$emit('selectQuestion', questionInfo);
                this.settingQuestionShow = false;
              }
            } else {
              this.settingQuestionShow = true;
            }
          })
          .catch(res => {
            this.settingQuestionShow = true;
            this.$message.error('添加失败');
          });
      },
      closeQuestion() {
        this.settingQuestionShow = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  .question-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.3);
    .question-select {
      position: absolute;
      top: 48%;
      left: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
      width: 500px;
      padding-bottom: 24px;
      border-radius: 4px;
      &_title {
        padding: 32px;
        font-size: 20px;
        i {
          float: right;
          font-size: 15px;
          color: #1a1a1a;
          cursor: pointer;
        }
      }
    }
    &_wrapper {
      display: flex;
      width: 100%;
      padding: 0 32px;
      .question-left {
        width: 70px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        color: #1a1a1a;
      }
      .question-right {
        width: calc(100% - 70px);
        &_appoint {
          margin: 8px 0;
          span {
            display: inline-block;
            background: #fff;
            width: 120px;
            height: 36px;
            border: 1px solid #d9d9d9;
            border-radius: 50px;
            font-size: 14px;
            color: #262626;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
            i {
              // vertical-align: middle;
              color: rgba(0, 0, 0, 0.85);
            }
            &:hover {
              background: #fb3a32;
              color: #fff;
              border: 1px solid transparent;
              i {
                color: #fff;
              }
            }
          }
        }
        &_list {
          max-height: 160px;
          overflow-y: auto;
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          margin: 16px 0;
          .list_item {
            padding: 3px 12px;
            background: #f0f0f0;
            border-radius: 50px;
            cursor: pointer;
            span {
              display: inline-block;
              max-width: 200px;
              color: #595959;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              vertical-align: middle;
              padding-right: 5px;
              line-height: 20px;
              font-size: 14px;
            }
            i {
              font-size: 12px;
              color: #595959;
              cursor: pointer;
            }
            &:hover {
              background: #fff2f0;
              span {
                color: #fb3a32;
              }
              i {
                color: #fb3a32;
              }
            }
            &.tags__error {
              border: 1px solid #fb3a32;
              background: #fff2f0;
              span {
                color: #fb3a32;
              }
              i {
                color: #fb3a32;
              }
            }
          }
        }
        &_error {
          color: #fb3a32;
          font-size: 14px;
          padding-top: 5px;
        }
      }
      .dialog-footer {
        float: right;
        padding: 0;
      }
    }
    .vh-radio {
      height: 40px;
      line-height: 40px;
    }
  }
</style>
