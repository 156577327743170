<template>
  <div class="vh-transfer-panel">
    <div class="vh-transfer-panel__header" @click="doClick">
      <div v-if="mode == 1" style="display: flex; justify-content: space-between">
        <div style="display: flex; width: 100%">
          <vh-checkbox
            v-model="allChecked"
            @change="handleAllCheckedChange"
            :indeterminate="isIndeterminate"
          ></vh-checkbox>
          {{ title }}
        </div>
        <div v-if="isShowNum" style="width: 100px; text-align: right; padding-right: 5px">
          {{ checkedSummary }}
          <vh-tooltip class="item" effect="dark" placement="top" :popper-options="{ zIndex: 4000 }">
            <div slot="content">
              <div class="audience-wrapper_tip">
                「在线用户」是一个组，即不论设置多少在线用户，均显示1/20
              </div>
            </div>
            <span class="iconfont-v3 saasicon_help_m"></span>
          </vh-tooltip>
        </div>
      </div>
      <template v-else>
        <span style="font-size: 16px">{{ title }}</span>
        <span class="refresh" @click="refresh">刷新</span>
      </template>
    </div>

    <div class="vh-transfer-panel__body" v-show="panelId == curId">
      <vh-input
        class="search"
        v-if="mode == 2"
        size="mini"
        v-model="query"
        @input="checkoutList"
        @keyup.enter.native="doSearch"
        placeholder="请输入昵称"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="doSearch"></i>
      </vh-input>
      <vh-checkbox-group
        v-model="checked"
        v-show="data.length > 0"
        :class="{ 'is-filterable': filterable }"
        class="vh-transfer-panel__list"
      >
        <vh-checkbox
          class="vh-transfer-panel__item"
          :label="item[keyProp]"
          :disabled="item[disabledProp]"
          :key="item[keyProp]"
          v-for="item in data"
        >
          <span :title="item[labelProp]">{{ item[labelProp] }}</span>
          <p
            class="error_title"
            v-show="isShowNum && errorData.includes(parseInt(item[keyProp] + ''))"
          >
            该观众组已被删除
          </p>
        </vh-checkbox>
      </vh-checkbox-group>
      <p class="vh-transfer-panel__empty" v-show="data.length === 0 && hasQuery">未搜索到数据</p>
      <p class="vh-transfer-panel__empty" v-show="data.length === 0 && !hasQuery">暂无数据</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ElTransferPanel',

    componentName: 'ElTransferPanel',

    components: {},

    props: {
      data: {
        type: Array,
        default() {
          return [];
        }
      },
      isShowNum: {
        type: Boolean,
        default: true
      },
      errorData: {
        type: Array,
        default() {
          return [];
        }
      },
      renderContent: Function,
      title: String,
      filterable: Boolean,
      format: Object,
      filterMethod: Function,
      defaultChecked: Array,
      props: Object,
      mode: {
        type: Number,
        default: 1
      },
      curId: {
        type: String,
        default: ''
      },
      panelId: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        checked: [],
        allChecked: false,
        query: '',
        inputHover: false,
        checkChangeByUser: true,
        hasQuery: false
      };
    },

    watch: {
      checked(val, oldVal) {
        this.updateAllChecked();
        if (this.checkChangeByUser) {
          const movedKeys = val
            .concat(oldVal)
            .filter(v => val.indexOf(v) === -1 || oldVal.indexOf(v) === -1);
          this.$emit('checked-change', val, movedKeys);
        } else {
          this.$emit('checked-change', val);
          this.checkChangeByUser = true;
        }
      },

      data() {
        const checked = [];
        const dataKeys = this.data.map(item => item[this.keyProp]);
        this.checked.forEach(item => {
          if (dataKeys.indexOf(item) > -1) {
            checked.push(item);
          }
        });
        this.checkChangeByUser = false;
        this.checked = checked;
      },

      checkableData() {
        this.updateAllChecked();
      },

      defaultChecked: {
        immediate: true,
        handler(val, oldVal) {
          if (
            oldVal &&
            val.length === oldVal.length &&
            val.every(item => oldVal.indexOf(item) > -1)
          )
            return;
          const checked = [];
          const checkableDataKeys = this.checkableData.map(item => item[this.keyProp]);
          val.forEach(item => {
            if (checkableDataKeys.indexOf(item) > -1) {
              checked.push(item);
            }
          });
          this.checkChangeByUser = false;
          this.checked = checked;
        }
      }
    },

    computed: {
      checkableData() {
        return this.data.filter(item => !item[this.disabledProp]);
      },

      checkedSummary() {
        const dataLength = this.data.length;
        const onlineCount = this.data.filter(item => item.type === 'user').length;
        const currentNum = dataLength - onlineCount + (onlineCount > 0 ? 1 : 0);
        return `${currentNum}/20`;
        // const checkedLength = this.checked.length;
        // const dataLength = this.data.length;
        // const { noChecked, hasChecked } = this.format;
        // if (noChecked && hasChecked) {
        //   return checkedLength > 0
        //     ? hasChecked.replace(/\${checked}/g, checkedLength).replace(/\${total}/g, dataLength)
        //     : noChecked.replace(/\${total}/g, dataLength);
        // } else {
        //   return `${checkedLength}/${dataLength}`;
        // }
      },

      isIndeterminate() {
        const checkedLength = this.checked.length;
        return checkedLength > 0 && checkedLength < this.checkableData.length;
      },

      inputIcon() {
        return this.query.length > 0 && this.inputHover ? 'circle-close' : 'search';
      },

      labelProp() {
        return this.props.label || 'label';
      },

      keyProp() {
        return this.props.key || 'key';
      },

      disabledProp() {
        return this.props.disabled || 'disabled';
      }
    },

    methods: {
      updateAllChecked() {
        const checkableDataKeys = this.checkableData.map(item => item[this.keyProp]);
        this.allChecked =
          checkableDataKeys.length > 0 &&
          checkableDataKeys.every(item => this.checked.indexOf(item) > -1);
      },

      handleAllCheckedChange(value, e) {
        this.checked = value ? this.checkableData.map(item => item[this.keyProp]) : [];
      },

      clearQuery() {
        if (this.inputIcon === 'circle-close') {
          this.query = '';
        }
      },

      doClick() {
        this.$emit('doClick', this.panelId);
      },
      doSearch() {
        this.hasQuery = this.query ? true : false;
        this.checked = [];
        this.$emit('doSearch', this.query);
      },
      refresh() {
        this.query = '';
        this.hasQuery = this.query ? true : false;
        this.checked = [];
        this.$emit('doRefresh', '');
      },
      checkoutList(newValue) {
        if (!newValue) {
          this.doSearch();
        }
      },
      initChecked() {
        this.checked = [];
      }
    }
  };
</script>
<style lang="less" scoped>
  .vh-transfer-panel {
    width: 255px;
    border-radius: 0;
    .refresh {
      float: right;
      margin-right: 10px;
      color: #3562fa;
      cursor: pointer;
    }
    .vh-transfer-panel__header {
      .vh-checkbox {
        margin-right: 8px;
      }
    }
    .vh-transfer-panel__body {
      .search {
        width: 229px;
        margin: 12px 12px 0;
        .vh-input__icon {
          cursor: pointer;
        }
      }
    }
  }
  .vh-transfer-panel__list {
    height: 100%;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .audience-list {
    .vh-transfer-panel {
      height: 351px !important;
    }
    .vh-transfer-panel__body {
      height: 311px !important;
    }
  }
  .user-pool,
  .win-blacker {
    // height: 180px;
    .vh-transfer-panel {
      height: 192px !important;
    }
    .vh-transfer-panel__body {
      height: 152px !important;
      overflow-y: auto;
    }
  }
  .error_title {
    line-height: 5px;
    height: 20px;
    font-size: 12px;
    color: #fb3a32;
  }
</style>
