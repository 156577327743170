<template>
  <div class="prize-card">
    <pageTitle :pageTitle="lotteryId ? '编辑抽奖' : '创建抽奖'"></pageTitle>
    <div class="card__body">
      <vh-tabs v-model="activeName" @tab-click="handleChangeTab">
        <vh-tab-pane label="抽奖页设置" name="first">
          <div class="give-item">
            <div class="give-prize">
              <vh-form :model="formData" ref="ruleForm" label-width="80px">
                <vh-form-item label="抽奖动效" required>
                  <div class="prize-type">
                    <div
                      class="przieImg prizeNoBorder"
                      :class="isChecked == 0 ? 'checkedBorder' : ''"
                    >
                      <upload
                        class="giftUpload"
                        v-model="previewSrc"
                        :domain_url="previewSrc"
                        :on-success="prizeLoadSuccess"
                        @delete="deleteImg"
                        @fullCover="changeType(0)"
                        :widthImg="86"
                        :heightImg="88"
                        :isFullCover="false"
                        :before-upload="beforeUploadHandler"
                      ></upload>
                      <label class="img-tangle" v-show="isChecked == 0">
                        <img src="../../../common/images/icon-choose.png" alt="" />
                      </label>
                    </div>
                    <p
                      :class="isChecked == 1 ? 'active' : ''"
                      class="przieImg"
                      @click="changeType(1)"
                    >
                      <img src="../../../common/images/gif/prize01.png" alt="" />
                      <label class="img-tangle" v-show="isChecked == 1">
                        <img src="../../../common/images/icon-choose.png" alt="" />
                      </label>
                    </p>
                    <p
                      :class="isChecked == 2 ? 'active' : ''"
                      class="przieImg"
                      @click="changeType(2)"
                    >
                      <img src="../../../common/images/gif/prize02.png" alt="" />
                      <label class="img-tangle" v-show="isChecked == 2">
                        <img src="../../../common/images/icon-choose.png" alt="" />
                      </label>
                    </p>
                    <p
                      :class="isChecked == 3 ? 'active' : ''"
                      class="przieImg"
                      @click="changeType(3)"
                    >
                      <img src="../../../common/images/gif/prize03.png" alt="" />
                      <label class="img-tangle" v-show="isChecked == 3">
                        <img src="../../../common/images/icon-choose.png" alt="" />
                      </label>
                    </p>
                  </div>
                  <p style="color: #999">建议尺寸：240*240px，小于2M 支持jpg、gif、png、bmp</p>
                </vh-form-item>
                <vh-form-item label="抽奖标题" required>
                  <VhallInput
                    v-model="formData.title"
                    autocomplete="off"
                    v-clearEmoij
                    :maxlength="100"
                    placeholder="请输入抽奖标题"
                    show-word-limit
                  ></VhallInput>
                </vh-form-item>
                <vh-form-item label="抽奖提示" required>
                  <VhallInput
                    v-model="formData.description"
                    autocomplete="off"
                    v-clearEmoij
                    :maxlength="20"
                    placeholder="请输入抽奖提示"
                    show-word-limit
                  ></VhallInput>
                </vh-form-item>
                <vh-form-item label="开奖时间">
                  <vh-radio-group v-model="formData.lottery_method" class="lottery_method">
                    <vh-radio :label="0">手动开奖</vh-radio>
                    <vh-radio :label="1">
                      推送抽奖后
                      <vh-input
                        v-model="formData.lottery_countdown"
                        maxlength="4"
                        placeholder="1-9999"
                        @input="inputCount"
                      >
                        <template slot="append">分钟</template>
                      </vh-input>
                      自动开奖
                    </vh-radio>
                  </vh-radio-group>
                </vh-form-item>
                <vh-form-item label="展示效果">
                  <p class="alertTxt">设置抽奖在手机观看端展示位置</p>
                  <vh-radio-group v-model="formData.display_of_results" class="position">
                    <vh-radio :label="0">全屏展示</vh-radio>
                    <vh-radio :label="1">半屏展示</vh-radio>
                  </vh-radio-group>
                </vh-form-item>
                <vh-form-item>
                  <!-- <vh-button @click="backToList" plain round size="medium" v-preventReClick>
                    返回列表
                  </vh-button> -->
                  <vh-button
                    round
                    type="primary"
                    size="medium"
                    :disabled="Boolean(!formData.title)"
                    @click="lotterySave"
                    v-preventReClick
                  >
                    保存
                  </vh-button>
                </vh-form-item>
              </vh-form>
            </div>
            <div class="give-show">
              <div class="give-people">
                <div
                  class="prize-mask"
                  :class="formData.display_of_results == 1 ? 'partScreen' : ''"
                >
                  <div class="prize-show" :style="`backgroundImage: url(${backgroundImg})`">
                    <div :class="`prize-title prize-title_0${isChecked}`">
                      {{ formData.description }}
                    </div>
                  </div>
                </div>
                <!-- <div class="sureBtn">{{ formData.description || '正在进行抽奖' }}</div> -->
              </div>
            </div>
          </div>
        </vh-tab-pane>
        <vh-tab-pane label="条件与中奖用户设置" name="four" :disabled="!lotteryId">
          <div class="give-item">
            <div class="give-prize-user give-live give-condition">
              <vh-form :model="conditionForm" label-width="140px">
                <vh-form-item label="参与条件" class="give-condition_option">
                  <vh-radio-group v-model="conditionForm.lottery_type" style="width: 550px">
                    <vh-radio :label="1">全体参会者</vh-radio>
                    <vh-radio :label="3" :disabled="configInfo['ui.hide_sign_in'] == 0">
                      完成签到的用户
                      <span class="color_red" v-if="configInfo['ui.hide_sign_in'] == 0">
                        未开通
                      </span>
                    </vh-radio>
                    <vh-radio :label="2" :disabled="configInfo['ui.hide_survey'] == 0">
                      提交问卷的用户
                      <span
                        class="set-btn"
                        @click="settingCondition(2)"
                        v-if="configInfo['ui.hide_survey'] == 1"
                      >
                        去设置
                      </span>
                      <span class="color_red" v-else>未开通</span>
                    </vh-radio>
                    <vh-radio :label="8" class="set-code">口令抽奖</vh-radio>
                    <vh-radio :label="21" :disabled="configInfo['exam'] == 0">
                      提交快问快答的用户
                      <span
                        class="set-btn"
                        @click="settingCondition(21)"
                        v-if="configInfo['exam'] == 1"
                      >
                        去设置
                      </span>
                      <span class="color_red" v-else>未开通</span>
                    </vh-radio>
                  </vh-radio-group>
                  <VhallInput
                    v-model="conditionForm.lottery_code"
                    style="width: 400px; display: block"
                    v-if="conditionForm.lottery_type == 8"
                    autocomplete="off"
                    v-clearEmoij
                    :maxlength="15"
                    placeholder="请设置口令"
                    show-word-limit
                  ></VhallInput>
                </vh-form-item>
                <vh-form-item
                  label="不满足条件的用户"
                  v-if="[2, 3, 21].includes(conditionForm.lottery_type)"
                >
                  <vh-radio v-model="conditionForm.lottery_tips" :label="0">
                    参与抽奖但不允许中奖，用户无感知
                  </vh-radio>
                  <br />
                  <vh-radio v-model="conditionForm.lottery_tips" :label="1">
                    不允许参与抽奖，给出强提醒
                    <span class="set-btn">
                      示例预览
                      <span class="example-preview">
                        <img src="./images/lottery_preview.png" alt="" />
                      </span>
                    </span>
                  </vh-radio>
                </vh-form-item>
                <vh-form-item label="设置用户" class="give-condition_user">
                  <p class="set_user">
                    设置「中奖用户池」及「中奖黑名单」
                    <template v-if="configInfo['white_list'] && configInfo['white_list'] == 1">
                      <span class="set_audience" @click="settingAudience">去设置</span>
                      <span v-if="!isEmbed" class="set_audience set_left" @click="showImportUser">
                        去观众组导入用户
                      </span>
                    </template>
                    <template v-else>
                      <span class="color_red">未开通</span>
                    </template>
                  </p>
                  <!-- <template v-if="configInfo['white_list'] && configInfo['white_list'] == 1"> -->
                  <div class="winner_pool" v-if="lotteryWinList.length">
                    <p class="set_user">「中奖用户池」</p>
                    <div class="set_list">
                      <div
                        class="set_list_item"
                        v-for="(item, index) in lotteryWinList"
                        :key="index"
                      >
                        <p>{{ item.subject }}</p>
                        <span v-show="item.type == 'group'">({{ item.num }}人)</span>
                        <span @click="deleteWinList(item)">
                          <i class="vh-icon-close"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="winner_pool" v-if="lotteryBlackList.length">
                    <p class="set_user">「不允许中奖名单」</p>
                    <div class="set_list">
                      <div class="set_list_item" v-for="item in lotteryBlackList" :key="item.key">
                        <p>{{ item.subject }}</p>
                        <span v-show="item.type == 'group'">({{ item.num }}人)</span>
                        <span @click="deleteBlackList(item)">
                          <i class="vh-icon-close"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- </template> -->
                </vh-form-item>
                <vh-form-item label="更多设置">
                  <vh-checkbox v-model="conditionForm.lottery_repeat">允许重复中奖</vh-checkbox>
                  <br />
                  <vh-checkbox v-model="conditionForm.can_remaining_lottery">
                    预设中奖人员少于奖品数时，多出奖品将无人中奖
                  </vh-checkbox>
                  <br />
                  <vh-checkbox v-model="conditionForm.lottery_look">
                    允许观众查看中奖名单
                  </vh-checkbox>
                </vh-form-item>
              </vh-form>
            </div>
          </div>
          <div class="condition_btn">
            <!-- <vh-button
              size="medium"
              type="info"
              plain
              round
              class="transparent-btn"
              v-preventReClick
              @click="backToList"
            >
              返回列表
            </vh-button>
            <vh-button size="medium" @click="backPreLottery('first')" type="info" plain round>
              上一步
            </vh-button> -->
            <vh-button
              size="medium"
              type="primary"
              round
              @click="sureConditionInfo"
              v-preventReClick
            >
              保存
            </vh-button>
          </div>
        </vh-tab-pane>
        <vh-tab-pane label="领奖页设置" name="second" :disabled="!lotteryId">
          <div class="give-item">
            <div class="give-prize give-live">
              <vh-form :model="givePrizeForm" label-width="100px">
                <vh-form-item label="领奖方式">
                  <vh-radio-group v-model="radioType">
                    <vh-radio label="1">寄送奖品</vh-radio>
                    <vh-radio label="2">私信兑奖</vh-radio>
                    <vh-radio label="3">无需领奖</vh-radio>
                  </vh-radio-group>
                </vh-form-item>
                <div v-if="radioType === '1'">
                  <div class="prize-con">
                    <div class="prize-js">
                      <vh-form-item
                        v-for="(item, index) in givePrizeList"
                        :key="index"
                        :label="item.field"
                        :ref="`${item.field_key}`"
                      >
                        <VhallInput
                          v-model="givePrizeForm[item.field_key]"
                          type="text"
                          show-word-limit
                          :maxlength="awardLimitNum[item.rank > 3 ? 3 : item.rank - 1]"
                          :placeholder="item.placeholder"
                        ></VhallInput>
                        <div class="isDelete" v-if="index > 0">
                          <i
                            class="iconfont-v3 saasicon-trash"
                            @click="deleteGivePrize(index)"
                            v-if="!Boolean(item.is_system)"
                          ></i>
                          <p class="switch__box">
                            <template v-if="item.field_key === 'phone'">
                              <vh-switch
                                v-model="item.is_check"
                                inactive-text="手机号校验"
                                :active-value="1"
                                :inactive-value="0"
                                @change="handlePhoneCheckChange"
                                style="margin-right: 15px"
                              ></vh-switch>
                            </template>
                            <vh-switch v-model="item.is_required" inactive-text="必填"></vh-switch>
                          </p>
                        </div>
                      </vh-form-item>
                      <div class="add-prize" @click="addField" v-if="givePrizeList.length < 6">
                        <i class="vh-icon-plus"></i>
                        添加字段
                      </div>
                      <vh-form-item label="强制领奖">
                        <vh-switch
                          v-model="compulsory_award"
                          :active-value="1"
                          :inactive-value="0"
                          active-text="开启后，中奖用户在未完成领奖时将无法关闭中奖页面及提交领奖页"
                        ></vh-switch>
                      </vh-form-item>
                      <vh-form-item label="隐私协议">
                        <ComplianceChoice
                          ref="complianceChoice"
                          v-model="privacyStatementData"
                          @change="complianceChange"
                        ></ComplianceChoice>
                      </vh-form-item>
                    </div>
                  </div>
                </div>
                <div v-if="radioType === '2'">
                  <div class="prize-con">
                    <div class="give-prize">
                      <vh-form :model="formData" ref="ruleForm" label-width="80px">
                        <vh-form-item label="私信号码">
                          <vh-input
                            v-model="privatePrizeForm.private_number"
                            autocomplete="off"
                            v-clearEmoij
                            placeholder="请输入私信号码"
                          ></vh-input>
                        </vh-form-item>
                        <vh-form-item label="二维码">
                          <upload
                            class="giftUpload"
                            v-model="privatePrizeForm.private_qrcode"
                            :domain_url="privatePrizeForm.private_qrcode"
                            :on-success="privatePrizeLoadSuccess"
                            @delete="deletePrivateImg"
                            :before-upload="beforeUploadHandler"
                          >
                            <div slot="tip">
                              <p>建议尺寸：240*240px，小于2M</p>
                              <p>支持jpg、gif、png、bmp</p>
                            </div>
                          </upload>
                        </vh-form-item>
                        <vh-form-item label="提示说明">
                          <vh-input
                            v-model="privatePrizeForm.tip_note"
                            autocomplete="off"
                            v-clearEmoij
                            :maxlength="20"
                            placeholder="请截图中奖页面添加客服领取奖品哦 "
                            show-word-limit
                          ></vh-input>
                        </vh-form-item>
                        <!-- <vh-form-item>
                          <vh-button type="primary"
                            round
                            @click="lotterySave"
                            v-preventReClick>返回列表</vh-button>
                          <vh-button type="primary"
                            round
                            @click="lotterySave"
                            v-preventReClick>保存并下一步</vh-button>
                        </vh-form-item> -->
                      </vh-form>
                    </div>
                  </div>
                </div>
                <div v-if="radioType === '3'" class="award-no">
                  <div class="award-no-wrapper">
                    <span><img src="./images/no-null.png" alt="" /></span>
                    <p class="award_no_tip">用户无需领取奖品</p>
                  </div>
                </div>
              </vh-form>
              <div class="award-save">
                <!-- <vh-button
                    size="medium"
                    type="info"
                    plain
                    round
                    class="transparent-btn"
                    v-preventReClick
                    @click="backToList"
                  >
                    返回列表
                  </vh-button>
                  <vh-button size="medium" @click="backPreLottery('four')" type="info" plain round>
                    上一步
                  </vh-button> -->
                <vh-button
                  type="primary"
                  size="medium"
                  round
                  @click="sureGivePrize"
                  :disabled="
                    radioType === '2' &&
                    !(privatePrizeForm.private_qrcode || privatePrizeForm.private_number)
                  "
                  v-preventReClick
                >
                  保存
                </vh-button>
              </div>
            </div>
            <!-- 预览 -->
            <div class="give-show">
              <div class="give-people">
                <div class="prize-mask">
                  <!-- 寄送奖品 -->
                  <div
                    v-if="radioType == 1"
                    class="accept-review way1"
                    :class="compulsory_award == 1 ? 'compulsoryAward' : ''"
                  >
                    <div v-if="!compulsory_award" class="has-close"></div>
                    <div class="padding-container">
                      <div class="give-msg">
                        <vh-form :model="givePrizeForm">
                          <vh-form-item
                            v-for="(item, index) in givePrizeList"
                            :key="index"
                            class="form-item"
                          >
                            <span class="red" v-if="item.is_required == 1">*</span>
                            <vh-input
                              v-model="givePrizeForm[item.field_key]"
                              readonly
                              type="text"
                              :placeholder="item.placeholder"
                            ></vh-input>
                          </vh-form-item>
                        </vh-form>
                      </div>
                    </div>
                    <div class="sureBtn">提交</div>
                    <span class="privacy-preview" v-html="privacyHtm"></span>
                  </div>

                  <!-- 私信兑奖 -->
                  <div class="accept-review way2" v-else-if="radioType == 2">
                    <div class="padding-container">
                      <div class="reward-container_service">
                        <div>
                          <p class="service-title" v-if="privatePrizeForm.private_number">
                            {{ privatePrizeForm.private_number }}
                          </p>
                          <div class="reward-service" v-if="privatePrizeForm.private_qrcode">
                            <img :src="privatePrizeForm.private_qrcode" alt="" />
                          </div>
                          <p class="service-title" v-if="privatePrizeForm.tip_note">
                            {{ privatePrizeForm.tip_note }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 无需领奖 -->
                  <div class="give-show_no" v-else>
                    <img src="./images/no-award.png" alt="" />
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </vh-tab-pane>
        <vh-tab-pane name="third" :disabled="!lotteryId">
          <span slot="label">
            奖品设置
            <vh-tooltip
              class="prize--set"
              effect="dark"
              placement="right"
              v-tooltipMove
              style="margin-left: 5px"
            >
              <i class="iconfont-v3 saasicon_help_m"></i>
              <div slot="content">
                1、每个抽奖最多显示20个奖品，超过20个后无法关联，需要将原有奖品删除
                <br />
                2、每个抽奖的奖品数累加不可超过2000个
                <br />
                3、优惠券可设置为奖品，建议结合「商品」进行使用，适用于直播带货场景
              </div>
            </vh-tooltip>
          </span>
          <div class="prize-info">
            <prize-list
              ref="prizeTable"
              @backToList="backToList"
              @backPreLottery="backPreLottery"
              :source="'0'"
              :lotteryId="lotteryId"
              :roomId="roomId"
            ></prize-list>
          </div>
        </vh-tab-pane>
      </vh-tabs>
    </div>
    <setting-question ref="settingQuestion" @selectQuestion="selectQuestion"></setting-question>
    <setting-exam ref="settingExamDom" @selectExam="selectExam"></setting-exam>
    <setting-audience ref="settingAudience" @selectAudience="selectAudience"></setting-audience>
    <begin-play :webinarId="$route.params.str"></begin-play>
  </div>
</template>

<script>
  import to from 'await-to-js';
  import PageTitle from '@/components/PageTitle';
  import upload from '@/components/Upload/main';
  import prizeList from '../../MaterialModule/prize';
  import beginPlay from '@/components/beginBtn';
  import prize0 from '@/common/images/gif/prize01.gif';
  import prize1 from '@/common/images/gif/prize02.gif';
  import prize2 from '@/common/images/gif/prize3.gif';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';
  import settingQuestion from './components/setting-question.vue';
  import settingExam from './components/setting-exam.vue';
  import settingAudience from './components/setting-audience.vue';
  import ComplianceChoice from '@/components/privacy-compliance/ComplianceChoice.vue';

  export default {
    name: 'prizeSet',
    data() {
      return {
        isEmbed: isEmbed(),
        prizeInfoStatus: false,
        backgroundImg: '',
        activeName: 'first',
        configInfo: JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage')),
        formData: {
          title: '',
          description: '抽奖进行中...',
          reDescription: '',
          reTitle: '',
          lottery_method: 0,
          lottery_countdown: '',
          display_of_results: 0
        },
        previewSrc: null,
        givePrizeForm: {
          adressCheced: false,
          name: '',
          phone: '',
          address: '',
          user_define_100: '',
          user_define_101: '',
          user_define_102: '',
          placeholder: ''
        },
        compulsory_award: 0,
        privatePrizeForm: {
          tip_note: '请截图中奖页面添加客服领取奖品哦 ',
          private_qrcode: '',
          private_number: ''
        },
        action: `${process.env.VUE_APP_BASE_URL}/v3/vss/lottery/save-prize-image`,
        total: 100,
        length: 0,
        isChecked: 1,
        localImg: 0,
        prizeUrl: [prize0, prize1, prize2],
        prizeInfo: {}, // 获得奖品的信息
        awardLimitNum: [20, 20, 50, 100],
        givePrizeList: [
          {
            is_system: 1,
            field: '姓名',
            field_key: 'name',
            is_required: true,
            placeholder: '请输入姓名',
            rank: 1
          },
          {
            is_system: 1,
            field: '手机号',
            field_key: 'phone',
            is_required: true,
            is_check: 1, // 1：按照11位手机号检测  0：按照20位数字检测
            placeholder: '请输入手机号',
            rank: 2
          },
          {
            is_system: 1,
            field: '联系地址',
            field_key: 'address',
            is_required: false,
            placeholder: '请输入地址',
            rank: 3
          }
        ],
        privatePrizeList: [
          {
            is_system: 1,
            field: '私信号码',
            field_key: 'private_number',
            is_required: false,
            placeholder: '',
            rank: 1
          },
          {
            is_system: 1,
            field: '二维码',
            field_key: 'private_qrcode',
            is_required: false,
            placeholder: '',
            rank: 2
          },
          {
            is_system: 1,
            field: '提示说明',
            field_key: 'tip_note',
            is_required: true,
            placeholder: '请截图中奖页面添加客服领取奖品哦',
            rank: 3
          }
        ],
        prizeForm: {
          name: '',
          imageUrl: ''
        },
        // 条件与中奖用户设置信息
        conditionForm: {
          lottery_type: 1,
          lottery_tips: 0,
          lottery_code: '',
          lottery_repeat: false,
          can_remaining_lottery: false,
          lottery_look: false
        },
        lotteryWinList: [],
        lotteryBlackList: [],
        lottery_question: {
          type: 1,
          survey_list: []
        },
        lottery_qa: {
          type: 1,
          score: '',
          fqa_list: []
        },
        index: 0,
        isError: false,
        rules: {
          name: [{ required: true, message: '请输入奖品名称', trigger: 'blur' }]
        },
        isLive: false,
        localLottery: {
          // 抽奖页信息
          description: '',
          title: ''
        },
        lotteryPageMessage: {
          // 领奖页信息
        },
        radioType: '1',
        partRadio: '1',
        lotteryId: '', // 当前的抽奖的id
        roomId: embedRoomId() || this.$route.query.roomId,
        // 隐私协议相关数据
        privacyStatementData: {
          statement_status: 0, //默认用系统自带协议
          statement_content: '',
          statement_info: []
        },
        privacyHtm: ''
      };
    },
    components: {
      PageTitle,
      upload,
      beginPlay,
      // createPrize,
      prizeList,
      settingQuestion,
      settingExam,
      settingAudience,
      ComplianceChoice
    },
    watch: {
      activeName() {
        if (this.activeName == 'first') {
          this.lotteryGet();
        } else if (this.activeName == 'second') {
          this.getGivePrize();
        } else if (this.activeName == 'third') {
          this.$refs.prizeTable.getTableList();
        } else if (this.activeName == 'four') {
          this.getConditionInfo();
        }
      }
    },
    created() {
      if (this.$route.query.lotteryId) {
        this.lotteryId = this.$route.query.lotteryId;
      }
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.backgroundImg = this.prizeUrl[0];
      console.log(this.configInfo, '??权限');
      // 问卷：perssionInfo['ui.hide_survey'] == 1
      // 快问快答：perssionInfo['exam'] == 1
      // 签到：['ui.hide_sign_in'] == 1
      // 观众：white_list
    },
    async mounted() {
      this.activeName = this.$route.query.index || 'first';
      await this.getActice();
      this.lotteryGet();
    },
    methods: {
      backToList() {
        this.$router.push({
          path: `${isEmbed() ? '/embed' : ''}/live/prizeList/${this.$route.params.str}`,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId
          }
        });
      },
      // 返回上一步
      backPreLottery(name) {
        this.handleChangeTab({ name });
      },
      settingCondition(index) {
        if (index == 2) {
          this.$refs.settingQuestion.openSettingDialog(this.lotteryId, this.lottery_question);
        } else {
          this.$refs.settingExamDom &&
            this.$refs.settingExamDom.open(this.lotteryId, this.lottery_qa);
        }
      },
      getActice() {
        return new Promise((resolve, reject) => {
          this.$fetch('getWebinarInfo', { webinar_id: this.$route.params.str }).then(res => {
            if (res.code == 200) {
              if (res.data.webinar_state == 1) {
                this.isLive = true;
              }
            } else {
              this.$vhMessage.warning(res.msg);
            }
            resolve();
          });
        });
      },
      // 条件与中奖用户设置 获取信息
      getConditionInfo() {
        let params = {
          webinar_id: this.$route.params.str,
          lottery_id: this.lotteryId
        };
        this.$fetch('getConditionInfo', params).then(res => {
          if (res.code === 200 && res.data) {
            this.conditionForm.lottery_type = res.data.lottery_type;
            this.conditionForm.lottery_look = Boolean(res.data.can_look_winner_list);
            this.conditionForm.lottery_repeat = Boolean(res.data.can_repeat_lottery);
            this.conditionForm.can_remaining_lottery = Boolean(res.data.can_remaining_lottery);
            this.conditionForm.lottery_tips = res.data.show_discontent_tips;
            const user_setting = res.data.user_setting && JSON.parse(res.data.user_setting);
            const winList = user_setting?.lottery_user_pool_list || [];
            const blackList = user_setting?.lottery_black_list || [];
            this.lotteryWinList =
              (winList.length &&
                winList.map(item => {
                  if (item.type == 2) {
                    return {
                      subject: item.name,
                      key: item.id,
                      num: item.num,
                      type: 'group'
                    };
                  } else {
                    return {
                      subject: item.name,
                      label: item.name,
                      key: item.id,
                      num: 0,
                      type: 'user'
                    };
                  }
                })) ||
              [];
            this.lotteryBlackList =
              (blackList.length &&
                blackList.map(item => {
                  if (item.type == 2) {
                    return {
                      subject: item.name,
                      key: item.id,
                      num: item.num,
                      type: 'group'
                    };
                  } else {
                    return {
                      subject: item.name,
                      label: item.name,
                      key: item.id,
                      num: 0,
                      type: 'user'
                    };
                  }
                })) ||
              [];
            const conditionInfo =
              res.data.lottery_condition && JSON.parse(res.data.lottery_condition);
            // 口令抽奖
            if (res.data.lottery_type == 8) {
              this.conditionForm.lottery_code = conditionInfo.command;
            }
            // 问卷
            if (res.data.lottery_type == 2) {
              this.lottery_question = conditionInfo;
            }
            // 问答
            if (res.data.lottery_type == 21) {
              this.lottery_qa = conditionInfo;
            }
          }
        });
      },
      // 保存条件设置
      sureConditionInfo() {
        let params = {
          webinar_id: this.$route.params.str,
          lottery_id: this.lotteryId,
          lottery_type: this.conditionForm.lottery_type,
          show_discontent_tips: this.conditionForm.lottery_tips,
          can_repeat_lottery: Number(this.conditionForm.lottery_repeat),
          can_remaining_lottery: Number(this.conditionForm.can_remaining_lottery),
          can_look_winner_list: Number(this.conditionForm.lottery_look),
          lottery_condition: null
        };
        // 口令
        if (this.conditionForm.lottery_type == 8) {
          if (!this.conditionForm.lottery_code) {
            this.messageInfo('请输入口令');
            return;
          }
          let code = {
            command: this.conditionForm.lottery_code
          };
          params.lottery_condition = JSON.stringify(code);
        }
        // 问卷
        if (this.conditionForm.lottery_type == 2) {
          if (this.lottery_question.type == 3 && !this.lottery_question.survey_list.length) {
            this.messageInfo('请选择指定问卷');
            return;
          }
          params.lottery_condition = JSON.stringify(this.lottery_question);
        }
        // 问答
        if (this.conditionForm.lottery_type == 21) {
          if (!this.lottery_qa.fqa_list.length) {
            this.messageInfo('请选择指定问答');
            return;
          }
          params.lottery_condition = JSON.stringify(this.lottery_qa);
        }
        const lotteryList = [...this.lotteryWinList, ...this.lotteryBlackList];
        if (lotteryList.length) {
          const groupList_win =
            this.lotteryWinList.filter(e => e.type == 'group').map(item => item.key) || [];
          const groupList_black =
            this.lotteryBlackList.filter(e => e.type == 'group').map(item => item.key) || [];
          const userList_win =
            this.lotteryWinList.filter(e => e.type == 'user').map(item => item.key) || [];
          const userList_black =
            this.lotteryBlackList.filter(e => e.type == 'user').map(item => item.key) || [];
          let lottery_user_pools = [];
          let lottery_black_list = [];
          //type:2-观众组,3在线用户
          groupList_win.map(e => {
            lottery_user_pools.push({
              id: e,
              type: 2
            });
          });
          groupList_black.map(e => {
            lottery_black_list.push({
              id: e,
              type: 2
            });
          });
          userList_win.map(e => {
            lottery_user_pools.push({
              id: e,
              type: 3
            });
          });
          userList_black.map(e => {
            lottery_black_list.push({
              id: e,
              type: 3
            });
          });
          const list = {
            lottery_user_pool_list: lottery_user_pools,
            lottery_black_list: lottery_black_list
          };
          params.user_setting = JSON.stringify(list);
        }
        this.$fetch('saveConditionInfo', params)
          .then(res => {
            if (res.code === 200) {
              this.messageInfo('保存成功', 'success');
              // this.handleChangeTab({ name: 'second' });
            } else {
              this.messageInfo(res.msg);
            }
          })
          .catch(e => {
            this.messageInfo(e.msg);
          });
      },
      // 条件与设置返回上一页
      // 问卷
      selectQuestion(info) {
        this.lottery_question = info;
      },
      messageInfo(text, type = 'error') {
        this.$vhMessage({
          message: text || '数据获取失败',
          showClose: true,
          // duration: 0,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      // 快问快答
      selectExam(info) {
        this.lottery_qa = info;
        console.log(info);
      },
      // 观众组选择
      selectAudience(winList, list) {
        this.lotteryWinList = winList;
        this.lotteryBlackList = list;
        console.log(this.lotteryWinList, this.lotteryBlackList, '???');
      },
      // 设置观众组
      settingAudience() {
        let white_list = this.configInfo?.white_list;
        if (white_list == 0) return;
        this.$refs.settingAudience.openSettingAudience(this.lotteryWinList, this.lotteryBlackList);
      },
      // 导入用户
      showImportUser() {
        let white_list = this.configInfo?.white_list;
        if (white_list == 0) return;
        let url = window.location.origin + process.env.VUE_APP_WEB_KEY + '/setting/viewer';
        window.open(url, '_blank');
      },
      // 移除中奖池
      deleteWinList(item) {
        this.lotteryWinList.splice(this.lotteryWinList.indexOf(item), 1);
      },
      // 移除黑名单
      deleteBlackList(item) {
        this.lotteryBlackList.splice(this.lotteryBlackList.indexOf(item), 1);
      },
      // 隐私协议变更
      complianceChange({ privacyHtm }) {
        this.privacyHtm = privacyHtm;
      },
      // 获取隐私协议数据
      async getPrivacyAgreement() {
        if (!this.lotteryId) return;
        const params = {
          webinar_id: this.$route.params.str,
          module: 2, // 所属模块： 1 观看限制 2 抽奖 3 互动有礼
          source_id: this.lotteryId
        };
        const [err, res] = await to(this.$fetch('getPrivacyAgreement', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return false;
        }
        this.privacyStatementData = {
          statement_status: res.data.statement_status,
          statement_content: res.data.statement_content || '',
          statement_info: res.data.statement_info || []
        };
      },
      // 保存隐私协议
      async savePrivacyAgreement() {
        if (this.privacyStatementData.statement_status === 1) {
          // 自定义协议时的校验
          if (
            !this.privacyStatementData.statement_content ||
            !Array.isArray(this.privacyStatementData.statement_info) ||
            this.privacyStatementData.statement_info.length === 0
          ) {
            this.$vhMessage.error('请完善自定义隐私协议数据');
            return;
          }
          const hasEmpty = this.privacyStatementData.statement_info.some(item => {
            return !item.title || !item.link;
          });
          if (hasEmpty) {
            this.$vhMessage.error('请完善自定义隐私协议数据');
            return;
          }
        }
        const params = {
          webinar_id: this.$route.params.str,
          module: 2, // 所属模块： 1 观看限制 2 抽奖 3 互动有礼
          source_id: this.lotteryId,
          ...this.privacyStatementData
        };
        if (Array.isArray(params.statement_info)) {
          // 保存提交的时候用字符串
          params.statement_info = JSON.stringify(params.statement_info);
        }
        const [err, res] = await to(this.$fetch('savePrivacyAgreement', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求出现异常');
          return false;
        }
        return true;
      },
      // 抽奖页面获取信息
      lotteryGet() {
        if (!this.lotteryId) return;
        let params = {
          webinar_id: this.$route.params.str,
          lottery_id: this.lotteryId
        };
        this.$fetch('getLivePrizeInfo', params)
          .then(res => {
            if (res.code == 200 && res.data) {
              this.formData.description = res.data.description;
              this.formData.title = res.data.title;
              this.formData.reDescription = res.data.description;
              this.formData.reTitle = res.data.title;
              this.formData.lottery_method =
                (res.data.lottery_method && parseInt(res.data.lottery_method + '')) || 0;
              this.formData.lottery_countdown =
                (res.data.lottery_countdown && parseInt(res.data.lottery_countdown + '')) || '';
              this.formData.display_of_results =
                (res.data.display_of_results && parseInt(res.data.display_of_results + '')) || 0;
              this.localLottery = res.data;
              if (parseInt(res.data.img_order) > 0) {
                this.isChecked = parseInt(res.data.img_order);
                // this.backgroundImg = res.data.img_path;
                this.backgroundImg = this.prizeUrl[res.data.img_order - 1] || res.data.img_path;
              } else {
                this.isChecked = 0;
                // this.backgroundImg = res.data.img_path;
                this.backgroundImg = this.prizeUrl[res.data.img_order - 1] || res.data.img_path;
                this.previewSrc = res.data.img_path;
              }
            }
          })
          .catch(err => {
            console.log('🚀 ~ file: prizeSet.vue ~ line 391 ~ this.$fetch ~ err', err);
            this.$vhMessage({
              message: err.msg || `获取信息失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 抽奖页保存按钮
      lotterySave() {
        if (this.formData.lottery_method == 1 && !this.formData.lottery_countdown) {
          this.$vhMessage({
            message: '请设置开奖时间',
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        let imgUrl = '';
        let k = 100309;
        let arrImg = [100309, 100310, 100311];
        if (parseInt(this.isChecked) > 0) {
          imgUrl = this.prizeUrl[this.isChecked - 1];
          k = arrImg[this.isChecked - 1];
        } else {
          imgUrl = this.previewSrc;
          k = 100312;
        }
        let params = {
          webinar_id: this.$route.params.str,
          title: this.formData.title,
          img_path: imgUrl,
          img_order: this.isChecked,
          lottery_id: this.lotteryId,
          description: this.formData.description,
          lottery_method: this.formData.lottery_method,
          display_of_results: this.formData.display_of_results
        };
        if (this.formData.lottery_method == 1) {
          params.lottery_countdown = this.formData.lottery_countdown;
        }
        this.$fetch('savePrizeInfo', params)
          .then(res => {
            this.lotteryId = res.data;
            this.$vhall_paas_port({
              k: k,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            if (this.formData.title !== this.formData.reTitle) {
              this.$vhall_paas_port({
                k: 100313,
                data: {
                  business_uid: this.userId,
                  user_id: '',
                  webinar_id: this.$route.params.str,
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
            }
            if (this.formData.description !== this.formData.reDescription) {
              this.$vhall_paas_port({
                k: 100314,
                data: {
                  business_uid: this.userId,
                  user_id: '',
                  webinar_id: this.$route.params.str,
                  refer: '',
                  s: '',
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
            }
            this.$vhMessage({
              message: `保存成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.handleChangeTab({ name: 'first' });
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg || '保存失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      setPrizePortData() {
        let prizeArr = ['', 100315, 100316, 100317];
        if (this.givePrizeList.length !== this.lotteryPageMessage.length) {
          let len = this.givePrizeList.length - 3;
          this.$vhall_paas_port({
            k: 100322,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.$vhall_paas_port({
            k: 100323,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: { length: len },
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.givePrizeList.forEach((ele, index) => {
          this.$vhall_paas_port({
            k: prizeArr[ele.rank],
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          if (ele.field === '手机号') {
            this.$vhall_paas_port({
              k: ele.is_required ? 100318 : 100319,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          }
          if (ele.field === '地址') {
            this.$vhall_paas_port({
              k: ele.is_required ? 100320 : 100321,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          }
        });
        // if (this.givePrizeForm.this.lotteryPageMessage)
      },
      changeImg() {
        this.$fetch('uploadImage')
          .then(res => {
            this.$vhMessage({
              message: `保存成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg || '保存失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      addField() {
        if (this.givePrizeList.length == 6) {
          this.$vhMessage({
            message: `最多只能添加三个自定义选项`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        this.index++;
        this.givePrizeList.push({
          is_system: 0,
          field: `自定义${this.index}`,
          field_key: 'user_define_' + (this.givePrizeList.length + 97),
          is_required: false,
          placeholder: '请输入备注信息',
          rank: this.givePrizeList.length + 97
        });
      },
      deleteGivePrize(index) {
        this.givePrizeList.splice(index, 1);
      },
      // 获取“领奖页设置”信息
      getGivePrize() {
        this.$fetch('getDrawPrizeInfo', {
          webinar_id: this.$route.params.str,
          lottery_id: this.lotteryId
        }).then(res => {
          this.radioType = res.data.receive_award_way + '';
          this.compulsory_award =
            (res.data.compulsory_award && parseInt(res.data.compulsory_award + '')) || 0;
          console.log(res.data, '获取领奖页信息');
          if (this.radioType == 1) {
            this.givePrizeList = JSON.parse(res.data.json_data);
            console.log('givePrizeList:', JSON.stringify(this.givePrizeList));
            // 深拷贝一个对象做对比
            this.lotteryPageMessage = JSON.parse(res.data.json_data);
            this.givePrizeList.forEach(ele => {
              this.givePrizeForm[ele.field_key] = ele.placeholder;
            });
            this.givePrizeList.map(item => {
              item.is_required = Boolean(item.is_required);
              // 补充is_check字段
              this.$set(item, 'is_check', Number(item.is_check));
            });
          } else if (this.radioType == 2) {
            this.privatePrizeList = JSON.parse(res.data.json_data);
            this.privatePrizeList.forEach(ele => {
              this.privatePrizeForm[ele.field_key] = ele.placeholder;
            });
          }
        });
        // 获取隐私协议数据
        this.getPrivacyAgreement();
      },
      // 保存“领奖页设置”信息
      async sureGivePrize() {
        if (this.radioType == 1) {
          // 保存隐私协议
          const isOk = await this.savePrivacyAgreement();
          if (!isOk) return;
        }

        let result = [];
        if (this.radioType == 1) {
          this.givePrizeList.forEach(ele => {
            // console.warn(this.givePrizeForm[ele.field_key], 789, this.givePrizeForm, ele.field_key);
            ele.placeholder = this.givePrizeForm[ele.field_key];
          });
          result = this.givePrizeList;
        } else if (this.radioType == 2) {
          this.privatePrizeList.forEach(ele => {
            ele.placeholder = this.privatePrizeForm[ele.field_key];
          });
          result = this.privatePrizeList;
        }

        console.warn(result);
        this.$fetch('saveDrawPrizeInfo', {
          webinar_id: this.$route.params.str,
          lottery_id: this.lotteryId,
          receive_award_way: this.radioType,
          compulsory_award: this.compulsory_award,
          data: JSON.stringify(result)
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage({
                message: `保存成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              // this.handleChangeTab({ name: 'third' });
              this.setPrizePortData();
            }
          })
          .catch(err => {
            this.$vhMessage({
              message: err.msg || '保存失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      handleChangeTab(tab) {
        this.activeName = tab.name;
        const query = {
          ...this.$route.query,
          index: tab.name
        };
        if (this.lotteryId) {
          query.lotteryId = this.lotteryId;
        }
        this.$router.push({
          path: `${isEmbed() ? '/embed' : ''}/live/prizeSet/${this.$route.params.str}`,
          query
        });
        if (tab.name === 'first') {
          this.lotteryGet();
        } else if (tab.name === 'second') {
          this.getGivePrize();
        } else if (tab.name === 'third') {
          this.$refs.prizeTable.getTableList();
        } else if (tab.name === 'four') {
          this.getConditionInfo();
        }
      },
      deleteImg() {
        this.previewSrc = '';
        this.isChecked = 1;
        this.backgroundImg = this.prizeUrl[0];
      },
      changeType(index) {
        this.isChecked = index;
        this.backgroundImg = this.prizeUrl[index - 1];
        if (!index) {
          this.backgroundImg = this.previewSrc;
        }
      },
      prizeLoadSuccess(res, file) {
        console.log('图片上传', res, 'ssssss', file);
        this.previewSrc = res.data.domain_url;
        this.isChecked = 0;
        this.backgroundImg = res.data.domain_url;
      },
      privatePrizeLoadSuccess(res, file) {
        console.log('图片上传', res, 'ssssss', file);
        this.privatePrizeForm.private_qrcode = res.data.domain_url;
      },
      deletePrivateImg() {
        this.privatePrizeForm.private_qrcode = '';
      },
      beforeUploadHandler(file) {
        console.log(file);
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        console.log(file.type.toLowerCase());
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage({
            message: `文件格式不支持，请检查图片`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `文件大小超过2M，请检查图片`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      createPrize() {
        this.$refs.createPrize.dialogVisible = true;
      },
      changePrize() {
        this.$refs.createPrize.dialogPrizeVisible = true;
      },
      inputCount(value) {
        this.formData.lottery_countdown = value.replace(/[^\d]+/, '');
      },
      async handlePhoneCheckChange(val) {
        if (!val) {
          const confirm = await this.$vhConfirm(
            '关闭后，只需要输入数字即可，将无法保证手机号的准确性，是否关闭？',
            '提示',
            {
              roundButton: true,
              confirmButtonText: '仍要关闭',
              cancelButtonText: '暂不关闭',
              customClass: 'zdy-message-box',
              lockScroll: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              cancelButtonClass: 'zdy-confirm-cancel'
            }
          ).catch(() => false);
          if (!confirm) {
            // 暂不关闭, 把开关状态还原回去
            for (const item of this.givePrizeList) {
              if (item.field_key === 'phone') {
                item.is_check = 1;
                break;
              }
            }
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .set-btn {
    color: #3562fa;
    position: relative;
    .example-preview {
      visibility: hidden;
      position: absolute;
      right: -155px;
      top: 0;
      width: 151px;
      height: 277px;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .example-preview {
        visibility: visible;
      }
    }
  }
  .set-code {
    margin-right: 38px;
  }
  .color_red {
    color: #fb3a32;
  }
  .prize-card {
    height: 100%;
    .card__body {
      padding: 0;
      background: #fff;
      // max-width: 1374px;
      margin: auto;
      border-radius: 4px;
      .lottery_method {
        display: flex;
        align-items: center;
        height: 40px;
        .vh-input {
          width: 85px;
          margin: 0 8px;
        }
      }
    }

    /deep/.vh-tabs__content {
      min-height: 700px;
    }
    .prize-info {
      margin: 12px 0;
      // .question-list{
      //   padding: 24px 0;
      // }
    }
    .prize-list {
      padding: 33px 24px;
      .head-operat {
        margin-bottom: 30px;
      }
    }
    .give-item {
      padding: 12px 24px 16px 24px;
      display: flex;
      /deep/.vh-form {
        position: relative;
      }
      .give-prize {
        width: 480px;
        margin-right: 80px;
        // .prize-con{
        //   display: flex;
        // }
        .isDelete {
          height: 20px;
          display: flex;
          justify-content: flex-end;
          i {
            font-size: 16px;
            vertical-align: top;
            line-height: 40px;
            cursor: pointer;
          }
        }
        .switch__box {
          display: inline-block;
          padding-left: 5px;
        }
        /deep/.vh-button {
          margin-top: 16px;
        }
        &-previewImg {
          width: 240px;
          height: 240px;
        }
      }
      .add-prize {
        margin-left: 100px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #3562fa;
        cursor: pointer;
        i {
          color: #3562fa;
          // border: 1px dashed #3562FA;
        }
        .vh-icon-plus:before {
          font-weight: bolder;
        }
      }
      .give-show {
        width: 370px;
        height: 631px;
        background-image: url('../../../common/images/gif/prize.png');
        background-size: cover;
        margin-top: -25px;
        position: absolute;
        left: 600px;
        top: 0;
        .give-people {
          .accept-review.way1 {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            width: 276px;
            height: auto;
            margin-left: 0;
            background-image: url('../../../common/images/gif/accept-review2.png');
            background-size: 100%;
            background-repeat: no-repeat;
            border-radius: 0 0 24px 24px;

            .has-close {
              position: absolute;
              top: 12px;
              right: 14px;
              &:after {
                content: '\00D7';
                font-size: 18px;
                font-weight: normal;
                color: #ffce70;
              }
            }

            .privacy-preview {
              display: block;
              position: relative;
              z-index: 2;
              font-size: 12px;
              padding: 10px 15px;
              line-height: 1.5;
              background: linear-gradient(37.94deg, #fffbe8 3.86%, #fbf0e6 84.36%);
              border-bottom-left-radius: 24px;
              border-bottom-right-radius: 24px;

              /deep/a {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.5;
                word-break: break-word;
                text-decoration: none;
                font-weight: 400;
                color: #3562fa;
                transform: matrix(0.99, 0, -0.14, 1, 0, 0);

                &:hover {
                  color: #3562fa;
                }
              }
            }
          }

          .accept-review.way2 {
            margin: auto;
            margin-top: 227px;
            width: 276px;
            height: 279px;
            margin-left: 0;
            background-image: url('../../../common/images/gif/accept-review.png');
            background-size: 100%;
            background-repeat: no-repeat;
            border-radius: 0 0 24px 24px;
          }

          h3 {
            text-align: center;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            color: #1a1a1a;
            position: relative;
            i {
              position: absolute;
              top: 12px;
              right: 12px;
              cursor: pointer;
            }
          }
          .sureBtn {
            width: 128px;
            margin: auto;
            text-align: center;
            background: #ffd1c9;
            color: #fff;
            font-size: 14px;
            height: 32px;
            line-height: 32px;
            border-radius: 20px;
          }

          .padding-container {
            padding-top: 45px;
          }
          ::-webkit-scrollbar {
            display: none;
          }
          .give-msg {
            text-align: center;
            margin: 10px auto 16px;
            height: 160px;
            padding: 0 16px;
            overflow-y: auto;

            p {
              margin-top: 24px;
            }
            .red {
              position: absolute;
              left: 0px;
              top: 2px;
              display: inline-block;
              color: #fe6a6a;
              margin-right: 10px;
            }
          }
          .form-item {
            // padding-left: 15px;
            background: #fff;
            border-radius: 4px;
            margin-bottom: 9px;
            /deep/ input {
              border: 0;
              color: #bfbfbf;
            }
          }
        }
        .prize-mask {
          position: absolute;
          top: 85px;
          left: 50px;
          width: 276px;
          height: 505px;
          background: rgba(#000, 0.7);
          border-radius: 0 0 24px 24px;
          &.partScreen {
            bottom: 42px;
            top: auto;
            height: 306px;
            background-size: cover;
            border-radius: 0 0 24px 24px;
            background: url('./images/modal.png') top no-repeat;
            .prize-show {
              margin-top: 0;
            }
          }
        }
        .prize-show {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0 auto;
          text-align: center;
          width: 200px;
          height: 230px;
          background-size: 100%;
          background-repeat: no-repeat;
          margin-top: 16px;
          background-position: center;
          .prize-title {
            position: absolute;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            color: #fff;
            transform-origin: center left;
          }
          .prize-title_00 {
            bottom: -10px;
            left: 30px;
            width: 150px;
          }
          .prize-title_01 {
            bottom: 24px;
            left: 70px;
            width: 90px;
            transform: scale(0.7);
          }
          .prize-title_02 {
            top: 25px;
            left: 55px;
            width: 118px;
            transform: scale(0.8);
          }
          .prize-title_03 {
            bottom: 72px;
            left: 75px;
            width: 75px;
            transform: scale(0.7);
          }
        }
      }
      .prize-type {
        display: flex;
        text-align: center;
        justify-content: space-between;
        .przieImg {
          width: 88px;
          height: 88px;
          border-radius: 4px;
          border: 1px solid #ccc;
          position: relative;
          transition: all 0.15s ease-in;
          &:hover {
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
          }
          &.active {
            border: 1px solid #fb3a32;
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
          }
          img {
            width: 100%;
            height: 100%;
          }
          .img-tangle {
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 20px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .prizeNoBorder {
          border: 0;
          /deep/.vh-upload--picture-card {
            height: 88px;
          }
          /deep/.vh-upload--picture-card .box > div {
            height: 88px;
          }
        }
        .checkedBorder {
          border: 1px solid #fb3a32 !important;
          /deep/.vh-upload--picture-card {
            border: 0;
            height: 86px;
          }
        }
      }
      .award-save {
        margin-left: 100px;
        /deep/.vh-button {
          margin-top: 28px;
        }
      }
    }
    .prize-no {
      height: calc(100vh - 260px);
      text-align: center;
      margin-top: 268px;
    }
  }
  .set_audience {
    color: #3562fa;
    cursor: pointer;
    &.set_left {
      margin-left: 12px;
    }
  }
  .set_user {
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
  }
  .set_list {
    max-height: 160px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    &_item {
      background: #f0f0f0;
      border-radius: 4px;
      padding: 0 12px;
      height: 30px;
      line-height: 30px;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      &:hover {
        p {
          color: #fb3a32;
        }
        span {
          color: #fb3a32;
        }
      }
      p {
        max-width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #595959;
        padding-right: 5px;
      }
      span {
        color: #595959;
        i {
          padding-left: 5px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .condition_btn {
    margin-left: 150px;
    // margin-top: 30px;
  }
  .award-no {
    width: 380px;
    height: 320px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    margin-left: 100px;
    &-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    span {
      width: 120px;
      height: 130px;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    p {
      line-height: 20px;
      margin-top: 12px;
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .reward-container_service {
    text-align: center;
    width: 248px;
    height: 212px;
    background: #fff;
    border-radius: 4px;
    margin: 10px auto;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .reward-service {
      width: 120px;
      height: 120px;
      margin: 0 auto;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
    .service-title {
      width: 218px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      line-height: 32px;
      height: 32px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .give-show_no {
    width: 267px;
    height: 257px;
    margin: 0 auto;
    margin-top: 98px;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  /deep/.saasicon_help_m {
    color: #999999;
  }
  .give-condition {
    /deep/.vh-radio {
      height: 40px;
    }
    &_option {
      /deep/.vh-form-item__label {
        line-height: 18px;
      }
    }
    &_user {
      /deep/.vh-form-item__label {
        line-height: 36px;
      }
    }
  }
</style>
<style lang="less">
  .prize-card {
    .card__body .give-prize {
      .vh-form-item__content {
        // display: flex;
        // align-items: center;
      }
      .lottery_method {
        display: flex;
        align-items: center;
        height: 40px;
        .vh-cascader-node > .vh-radio,
        .vh-radio:last-child {
          display: flex;
          align-items: center;
          .vh-radio__label {
            display: flex;
            align-items: center;
          }
          .vh-input-group__append {
            padding: 0 8px;
          }
        }
        .vh-input {
          width: 114px;
          margin: 0 8px;
        }
      }
      .prize-con {
        .vh-switch__label > span {
          line-height: 1.4;
          // color: #262626;
        }
      }
      .vh-switch__label.is-active {
        color: #8c8c8c;
      }
    }
  }
</style>
