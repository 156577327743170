<template>
  <div>
    <vh-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      title="设置用户"
      modal
      width="640px"
      custom-class="setting-audience-dialog"
      append-to-body
    >
      <div slot="title">
        设置用户
        <vh-tooltip class="item" effect="dark" placement="right" :popper-options="{ zIndex: 4000 }">
          <div slot="content">
            <div class="audience-wrapper_tip">
              设置说明：
              <br />
              1.
              当预设中奖人员少于奖品数时，则中奖用户取全部预设人员+普通参会人员，如果仅需要预设人员中奖，请在更多设置中勾选「预设中奖人员少于奖品数时，多出奖品将无人中奖
              <br />
              2. 当预设中奖人员多于奖品数时，则中奖用户优先取预设人员中的在线用户（随机）
              <br />
              3. 预设在线用户功能，抽奖时若用户不在线将无法中奖
              <br />
              4.
              分组来源：运营设置-观众设置；选中组内的用户无需在直播间也可以中奖；以手机号/邮箱作为关键标识
            </div>
          </div>
          <span class="iconfont-v3 saasicon_help_m"></span>
        </vh-tooltip>
      </div>
      <div class="audience-wrapper">
        <div class="audience-wrapper_list">
          <el-transfers
            ref="transfers"
            :value1.sync="winnerList"
            :value2.sync="blackList"
            :data="audienceList"
            :value4="onlineList"
            :value3="errorList"
            @loadMore="loadMore"
            @doSearch="doSearch"
            @doRefresh="doRefresh"
            :titles="['在线用户', '观众组', '中奖用户池', '中奖黑名单']"
          ></el-transfers>
        </div>
        <div class="audience-wrapper_btn">
          <vh-button size="medium" type="info" v-preventReClick plain round @click="handleClose">
            取消
          </vh-button>
          <vh-button
            type="primary"
            :disabled="!(winnerList.length || blackList.length)"
            size="medium"
            round
            v-preventReClick
            @click="sureAudience"
          >
            确定
          </vh-button>
        </div>
      </div>
    </vh-dialog>
  </div>
</template>
<script>
  import elTransfers from '@/components/Transfer/el-transfer';
  import { sessionOrLocal, embedRoomId } from '@/utils/utils';
  export default {
    data() {
      return {
        dialogVisible: false,
        total: 0,
        pageInfo: {
          limit: 500,
          pageNum: 1
        },
        audienceList: [],
        winnerList: [],
        onlineList: [],
        blackList: [], // 列表
        errorList: [],
        roomId: embedRoomId() || this.$route.query.roomId
      };
    },
    components: { elTransfers },
    methods: {
      openSettingAudience(winList, list) {
        console.log(winList, list);
        this.dialogVisible = true;
        this.winnerList = winList || [];
        this.blackList = list || [];
        this.errorList = [];
        this.initChecked();
        this.getOnlineList();
        this.getAudienceList();
      },
      loadMore() {
        // const pos = (this.pageInfo.pageNum - 1) * this.pageInfo.limit;
        // if (pos >= this.total) return false;
        // this.pageInfo.pageNum++;
        // this.getAudienceList();
      },
      getAudienceList() {
        const userId = JSON.parse(sessionOrLocal.get('userId'));
        const params = {
          user_id: userId,
          is_filter_empty_phone: 2,
          is_require_audience_num: 1,
          limit: this.pageInfo.limit,
          pos: (this.pageInfo.pageNum - 1) * this.pageInfo.limit
        };
        this.$fetch('audienceGet', params).then(res => {
          if (res.code === 200) {
            this.total = res.data.total;
            if (res.data.total) {
              let list = res.data.list.map(item => {
                return {
                  key: item.id + '',
                  subject: item.subject,
                  num: item.audience_num,
                  label: `${item.subject}（${item.audience_num}）`,
                  type: 'group'
                };
              });
              this.audienceList.push(...list);
              const blackList_key = this.blackList.map(item => item.key) || [];
              const winnerList_key = this.winnerList.map(item => item.key) || [];

              const winnerList = list.filter(item => winnerList_key.indexOf(item.key) > -1);
              const blackList = list.filter(item => blackList_key.indexOf(item.key) > -1);
              const winners = this.winnerList.filter(e => e.type == 'user');
              const blacks = this.blackList.filter(e => e.type == 'user');
              this.winnerList = [...winnerList, ...winners] || [];
              this.blackList = [...blackList, ...blacks] || [];
              console.log(this.winnerList, this.blackList, '????123');
            }
          }
        });
      },
      async sureAudience() {
        //点击确定之前，调用刷新操作
        await this.doRefresh();
        const list = [...this.winnerList, ...this.blackList];

        if (this.winnerList.length > 20 || this.blackList.length > 20) {
          this.$vhMessage.error('最大仅可设置20个用户/组');
          return;
        }
        const list_key = list.filter(e => e.type == 'group').map(item => item.key) || [];
        const winners = this.winnerList.filter(e => e.type == 'user');
        const blacks = this.blackList.filter(e => e.type == 'user');
        let pass_status = false;
        let del_ids = [];
        if (list_key.length == 0) {
          pass_status = true;
        } else {
          const userId = JSON.parse(sessionOrLocal.get('userId'));
          let params = {
            group_ids: list_key.join(','),
            user_id: userId
          };
          const res = await this.$fetch('checkAudienceInfo', params);
          if (res.code === 200) {
            pass_status = res.data.pass_status;
            if (pass_status) {
            } else {
              del_ids = res.data?.del_ids;
            }
          }
        }

        if (pass_status) {
          const winnerList = [];
          const blackList = [];
          const blackList_key = this.blackList.map(item => item.key) || [];
          const winnerList_key = this.winnerList.map(item => item.key) || [];

          this.audienceList.map(item => {
            if (winnerList_key.length && winnerList_key.includes(item.key)) {
              winnerList.push({
                key: item.key,
                subject: item.subject,
                num: item.num,
                type: 'group'
              });
            }
            if (blackList_key.length && blackList_key.includes(item.key)) {
              blackList.push({
                key: item.key,
                subject: item.subject,
                num: item.num,
                type: 'group'
              });
            }
          });

          this.$emit('selectAudience', [...winnerList, ...winners], [...blackList, ...blacks]);
          this.handleClose();
        } else {
          this.dialogVisible = true;
          let list = del_ids;
          this.errorList = list.split(',').map(item => Number(item));
        }
      },
      handleReset() {
        this.errorList = [];
        this.audienceList = [];
        this.pageInfo.pageNum = 1;
      },
      handleClose() {
        this.dialogVisible = false;
        this.handleReset();
      },
      doSearch(txt) {
        this.getOnlineList(txt);
      },
      async doRefresh(txt) {
        await this.getOnlineList(txt);
        // await this.checkUserOnline();
      },
      async getOnlineList(query) {
        const params = {
          room_id: this.roomId,
          limit: 200,
          pos: 0
        };
        if (query) {
          params.nickname = query;
        }
        const res = await this.$fetch('getOnlineUserList', params);
        if (res.code === 200) {
          this.onlineList = [];
          this.total = res.data.total;
          if (res.data.total) {
            let list = res.data.list.map(item => {
              return {
                key: item.account_id,
                join_id: item.join_id,
                room_id: item.room_id,
                subject: item.nickname,
                num: 0,
                label: item.nickname,
                type: 'user'
              };
            });
            this.onlineList.push(...list);
          }
        }
      },
      initChecked() {
        this.$refs.transfers && this.$refs.transfers.initChecked();
      }
      // async checkUserOnline() {
      //   const winners = this.winnerList.filter(e => e.type == 'user').map(e => e.key);
      //   const blacks = this.blackList.filter(e => e.type == 'user').map(e => e.key);
      //   let ids = [...winners, ...blacks].join(',');
      //   if (ids) {
      //     const params = {
      //       account_ids: ids,
      //       room_id: this.roomId
      //     };
      //     const res = await this.$fetch('checkUserOnline', params);
      //     if (res.code === 200) {
      //       const obj = res.data;
      //       for (let i in obj) {
      //         for (let j = 0; j < this.winnerList.length; j++) {
      //           if (this.winnerList[j].key == i) {
      //             this.$set(this.winnerList[j], 'online', obj[i]);
      //             break;
      //           }
      //         }
      //         for (let j = 0; j < this.blackList.length; j++) {
      //           if (this.blackList[j].key == i) {
      //             this.$set(this.blackList[j], 'online', obj[i]);
      //             break;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    },
    mounted() {}
  };
</script>
<style lang="less">
  .setting-audience-dialog {
    .audience-wrapper {
      width: 100%;
      &_list {
        // height: 360px;
        display: flex;
        justify-content: space-between;
        .list_left,
        .list_right {
          width: 260px;
        }
        .el-checkbox-group {
          // display: flex;
          // flex-direction: column;
          padding-left: 12px;
          .el-checkbox {
            height: 36px;
            line-height: 36px;
            display: block;
          }
          .el-checkbox__label {
            color: #262626;
            font-weight: normal;
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #fb3a32;
          }
        }
        .audience_title {
          margin-top: 1px;
          height: 40px;
          background: #fafafa;
          line-height: 40px;
          padding-left: 12px;
          color: #262626;
          .el-checkbox {
            padding-right: 2px;
          }
        }
        .list_left {
          border: 1px solid #d9d9d9;
          border-radius: 4px;
        }
        .list_right {
          &_wrap {
            height: 170px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            &:last-child {
              margin-top: 8px;
            }
          }
        }
        .list_center {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          &_wrap {
            text-align: center;
            span {
              display: inline-block;
              width: 32px;
              height: 32px;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              margin-top: 8px;
              cursor: pointer;
              i {
                line-height: 32px;
                font-size: 12px;
              }
            }
          }
        }
        .audience_list {
          height: calc(100% - 40px);
          overflow-y: auto;
        }
      }
      &_tip {
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
        margin-top: 8px;
      }
      &_btn {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
</style>
