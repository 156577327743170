<template>
  <div class="vh-transfer">
    <div class="panelLeft">
      <transfer-panel
        :mode="2"
        panelId="panel1"
        ref="panel1"
        :curId="curId"
        v-bind="$props"
        v-on="$listeners"
        :data="sourceData1"
        :title="titles[0]"
        :default-checked="leftDefaultChecked1"
        :isShowNum="false"
        @checked-change="onSourceCheckedChange1"
        class="audience-list"
        @doClick="doClickPanel"
      ></transfer-panel>
      <transfer-panel
        ref="panel2"
        :curId="curId"
        panelId="panel2"
        v-bind="$props"
        :data="sourceData2"
        :title="titles[1]"
        :isShowNum="false"
        :default-checked="leftDefaultChecked2"
        @checked-change="onSourceCheckedChange2"
        class="audience-list"
        @doClick="doClickPanel"
      ></transfer-panel>
    </div>

    <div class="vh-transfer__buttons">
      <div class="win-user-pool">
        <vh-button
          type="primary"
          class="vh-transfer__button"
          @click.native="addToLeft1"
          :disabled="rightChecked1.length === 0"
        >
          <i class="vh-icon-arrow-left"></i>
        </vh-button>
        <br />
        <vh-button
          type="primary"
          class="vh-transfer__button"
          @click.native="addToRight1"
          :disabled="leftChecked1.length === 0 && leftChecked2.length === 0"
        >
          <i class="vh-icon-arrow-right"></i>
        </vh-button>
      </div>
      <div class="win-blacker">
        <vh-button
          type="primary"
          class="vh-transfer__button"
          @click.native="addToLeft2"
          :disabled="rightChecked2.length === 0"
        >
          <i class="vh-icon-arrow-left"></i>
        </vh-button>
        <br />
        <vh-button
          type="primary"
          class="vh-transfer__button"
          @click.native="addToRight2"
          :disabled="leftChecked1.length === 0 && leftChecked2.length === 0"
        >
          <i class="vh-icon-arrow-right"></i>
        </vh-button>
      </div>
    </div>
    <div class="right-style">
      <div class="user-pool">
        <transfer-panel
          v-bind="$props"
          :data="value1"
          ref="panel3"
          :title="titles[2]"
          :errorData="value3"
          :default-checked="rightDefaultChecked1"
          @checked-change="onTargetCheckedChange1"
        ></transfer-panel>
      </div>
      <div class="win-blacker">
        <transfer-panel
          v-bind="$props"
          :data="value2"
          ref="panel4"
          :title="titles[3]"
          :errorData="value3"
          :default-checked="rightDefaultChecked2"
          @checked-change="onTargetCheckedChange2"
        ></transfer-panel>
      </div>
    </div>
  </div>
</template>

<script>
  import TransferPanel from './transfer-panel.vue';

  export default {
    name: 'ElTransfer',
    components: {
      TransferPanel
    },

    props: {
      data: {
        type: Array,
        default() {
          return [];
        }
      },
      titles: {
        type: Array,
        default() {
          return [];
        }
      },

      leftDefaultChecked1: {
        type: Array,
        default() {
          return [];
        }
      },
      leftDefaultChecked2: {
        type: Array,
        default() {
          return [];
        }
      },
      rightDefaultChecked1: {
        type: Array,
        default() {
          return [];
        }
      },
      rightDefaultChecked2: {
        type: Array,
        default() {
          return [];
        }
      },
      value1: {
        type: Array,
        default() {
          return [];
        }
      },
      value2: {
        type: Array,
        default() {
          return [];
        }
      },
      // 错误数据
      value3: {
        type: Array,
        default() {
          return [];
        }
      },
      value4: {
        type: Array,
        default() {
          return [];
        }
      },
      format: {
        type: Object,
        default() {
          return {};
        }
      },
      props: {
        type: Object,
        default() {
          return {
            label: 'label',
            key: 'key',
            disabled: 'disabled'
          };
        }
      }
    },

    data() {
      return {
        leftChecked1: [],
        leftChecked2: [],
        rightChecked1: [],
        rightChecked2: [],
        curId: 'panel1'
      };
    },

    computed: {
      sourceData2() {
        return this.data.filter(item => {
          const value_key1 = this.value1.map(item => item[this.props.key]) || [];
          const value_key2 = this.value2.map(item => item[this.props.key]) || [];
          return (
            value_key1.indexOf(item[this.props.key]) === -1 &&
            value_key2.indexOf(item[this.props.key]) === -1
          );
        });
      },
      sourceData1() {
        return this.value4.filter(item => {
          const value_key1 = this.value1.map(item => item[this.props.key]) || [];
          const value_key2 = this.value2.map(item => item[this.props.key]) || [];
          return (
            value_key1.indexOf(item[this.props.key]) === -1 &&
            value_key2.indexOf(item[this.props.key]) === -1
          );
        });
      }
    },

    methods: {
      onSourceCheckedChange1(val, movedKeys) {
        this.leftChecked1 = val;
      },
      onSourceCheckedChange2(val, movedKeys) {
        this.leftChecked2 = val;
      },

      onTargetCheckedChange1(val, movedKeys) {
        this.rightChecked1 = val;
      },
      onTargetCheckedChange2(val, movedKeys) {
        this.rightChecked2 = val;
      },

      addToLeft1() {
        let currentValue = this.value1.slice();
        this.rightChecked1.forEach(item => {
          for (let i = 0; i < currentValue.length; i++) {
            const element = currentValue[i];
            if (element[this.props.key] == item) {
              currentValue.splice(i, 1);
              break;
            }
          }
        });
        this.$emit('update:value1', currentValue);
      },
      addToLeft2() {
        let currentValue = this.value2.slice();
        this.rightChecked2.forEach(item => {
          for (let i = 0; i < currentValue.length; i++) {
            const element = currentValue[i];
            if (element[this.props.key] == item) {
              currentValue.splice(i, 1);
              break;
            }
          }
        });
        this.$emit('update:value2', currentValue);
      },

      addToRight1() {
        let currentValue = this.value1.slice();
        const itemsToBeMoved = [];
        const key = this.props.key;
        const value_key1 = this.value1.map(item => item[this.props.key]) || [];
        this.data.forEach(item => {
          const itemKey = item[key];
          if (this.leftChecked2.indexOf(itemKey) > -1 && value_key1.indexOf(itemKey) === -1) {
            itemsToBeMoved.push(item);
          }
        });
        this.value4.forEach(item => {
          const itemKey = item[key];
          if (this.leftChecked1.indexOf(itemKey) > -1 && value_key1.indexOf(itemKey) === -1) {
            itemsToBeMoved.push(item);
          }
        });
        // 2023-12-26 需求变更，所有在线用户按一个组算数量
        const tmpArr = currentValue.concat(itemsToBeMoved);
        const onlineCount = tmpArr.filter(item => item.type === 'user').length;
        const currentNum = tmpArr.length - onlineCount + (onlineCount > 0 ? 1 : 0);
        if (currentNum > 20) {
          this.$vhMessage.error('最大仅可设置20个组');
          return;
        }
        currentValue = currentValue.concat(itemsToBeMoved);
        this.$emit('update:value1', currentValue);
      },
      addToRight2() {
        let currentValue = this.value2.slice();
        const itemsToBeMoved = [];
        const key = this.props.key;
        const value_key2 = this.value2.map(item => item[this.props.key]) || [];
        this.data.forEach(item => {
          const itemKey = item[key];
          if (this.leftChecked2.indexOf(itemKey) > -1 && value_key2.indexOf(itemKey) === -1) {
            itemsToBeMoved.push(item);
          }
        });
        this.value4.forEach(item => {
          const itemKey = item[key];
          if (this.leftChecked1.indexOf(itemKey) > -1 && value_key2.indexOf(itemKey) === -1) {
            itemsToBeMoved.push(item);
          }
        });
        // 2023-12-26 需求变更，所有在线用户按一个组算数量
        const tmpArr = currentValue.concat(itemsToBeMoved);
        const onlineCount = tmpArr.filter(item => item.type === 'user').length;
        const currentNum = tmpArr.length - onlineCount + (onlineCount > 0 ? 1 : 0);
        if (currentNum > 20) {
          this.$vhMessage.error('最大仅可设置20个组');
          return;
        }
        currentValue = currentValue.concat(itemsToBeMoved);
        this.$emit('update:value2', currentValue);
      },
      doClickPanel(id) {
        this.curId = id;
      },
      initChecked() {
        this.$refs.panel2 && this.$refs.panel2.initChecked();
        this.$refs.panel3 && this.$refs.panel3.initChecked();
        this.$refs.panel4 && this.$refs.panel4.initChecked();
      }
    }
  };
</script>
<style lang="less" scoped>
  .vh-transfer {
    display: flex;
    .panelLeft {
      display: flex;
      flex-direction: column;
    }
    .vh-transfer__buttons {
      vertical-align: top;
      padding: 0 14px;
      .vh-transfer__button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        padding: 0;
      }
      .is-disabled {
        background: #ffffff !important;
        border: 1px solid #d9d9d9 !important;
        color: #000 !important;
      }
      .win-user-pool {
        margin-top: 55px;
      }
      .win-blacker {
        margin-top: 100px;
      }
    }
    /deep/.vh-transfer__button > span {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      // justify-content: center;
    }
    .right-style {
      display: inline-block;
      vertical-align: top;
      .win-blacker {
        margin-top: 10px;
      }
    }
  }
</style>
